import { MatSnackBar } from '@angular/material';
import { NavigationStart, Router } from '@angular/router';
import { AlertDuration } from '@app/_helpers/enums';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/snack-bar";
import * as i3 from "@ngx-translate/core";
var AlertService = /** @class */ (function () {
    function AlertService(router, snackBar, translate) {
        var _this = this;
        this.router = router;
        this.snackBar = snackBar;
        this.translate = translate;
        this.subject = new Subject();
        this.keepAfterNavigationChange = false;
        console.log('AlertService created');
        // clear alert message on route change
        router.events.subscribe(function (event) {
            if (event instanceof NavigationStart) {
                if (!_this.keepAfterNavigationChange) {
                    // clear alert
                    _this.subject.next();
                }
            }
        });
        // Log messages to console
        this.getMessage()
            .pipe(filter(function (message) { return message != null && message.type != null && message.text != null; }))
            .subscribe(function (message) {
            console.log("Alert " + message.type + ": " + message.text);
        });
        // Log messages snackbar
        this.getMessage().subscribe(function (message) {
            if (message && message.text != null) {
                _this.snackBar.open(message.text, _this.translate.instant('CLOSE'), {
                    duration: message.duration.valueOf(),
                    panelClass: message.type === 'error' ? 'warning' : null
                });
            }
            else {
                _this.snackBar.dismiss();
            }
        });
    }
    /**
     * Show succes message alert
     * @param message Message to display
     * @param keepAfterNavigationChange Keep alert while navigating
     * @param duration Duration to show the display
     */
    AlertService.prototype.success = function (message, keepAfterNavigationChange, duration) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        if (duration === void 0) { duration = AlertDuration.Short; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, duration: duration });
    };
    /**
     * Show error message alert
     * @param message Message to display
     * @param keepAfterNavigationChange Keep alert while navigating
     * @param duration Duration to show the display
     */
    AlertService.prototype.error = function (message, keepAfterNavigationChange, duration) {
        if (keepAfterNavigationChange === void 0) { keepAfterNavigationChange = false; }
        if (duration === void 0) { duration = AlertDuration.Long; }
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, duration: duration });
    };
    /**
     * Get message
     */
    AlertService.prototype.getMessage = function () {
        return this.subject;
    };
    AlertService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.MatSnackBar), i0.ɵɵinject(i3.TranslateService)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
