<div mat-dialog-content>
    <mat-list *ngIf="secureLocations.length === 0">
        <mat-list-item [ta]="{ locationId: 'no locations' }">
            <mat-icon mat-list-icon>my_location</mat-icon>
            <h4 mat-line>{{ 'SECURELOCATIONS.NOTSECURE' | translate }}</h4>
            <p mat-line><br />{{ 'SECURELOCATIONS.TRYELSEWHERE' | translate }}</p>
        </mat-list-item>
    </mat-list>
    <mat-nav-list *ngIf="secureLocations.length > 0">
        <mat-list-item
            *ngFor="let location of secureLocations"
            (click)="dialogRef.close(location)"
            [ta]="{ locationId: location.locationId }"
        >
            <mat-icon mat-list-icon>my_location</mat-icon>
            <h4 mat-line>{{ location.organisation }}</h4>
            <p mat-line>{{ location.location }}</p>
        </mat-list-item>
    </mat-nav-list>
</div>
