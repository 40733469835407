import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { HttpClientActiveRequestsService } from '@app/_services';
import * as Hammer from 'hammerjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [
        trigger('progress', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('.4s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(0%)' }))
            ]),
            transition(':leave', [
                animate('.3s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'translateY(-100%)' }))
            ])
        ]),
        trigger('background', [
            transition(':enter', [
                style({ transform: 'scale(1.1)', opacity: 0 }),
                animate('.4s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'scale(1)', opacity: '*' }))
            ]),
            transition(':leave', [
                animate('.3s cubic-bezier(0.4, 0.0, 0.2, 1)', style({ transform: 'scale(1.1)', opacity: 0 }))
            ])
        ])
    ]
})
export class AppComponent implements OnInit
{
    background = false;
    progress$ = this.activeRequestsService.any().pipe(debounceTime(100));

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private activeRequestsService: HttpClientActiveRequestsService)
    { }

    ngOnInit()
    {
        // Set device properties for CSS selectors
        const html = this.document.documentElement;

        const touchDevice = !!('ontouchstart' in window) || !!('onmsgesturechange' in window);

        html.setAttribute('data-useragent', navigator.userAgent);
        html.setAttribute('data-platform', navigator.platform);

        if (touchDevice)
        {
            html.classList.add('touch');
        }

        // Set defaults for HammerJS
        Hammer.defaults.touchAction = 'pan-y'; // allow touch to scroll vertically
    }

}
