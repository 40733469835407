import { JwtHelperService } from '@auth0/angular-jwt';
import * as i0 from "@angular/core";
// Set constants
var TOKEN_KEY = 'token';
var JWT_HELPER = new JwtHelperService();
var TokenStoreService = /** @class */ (function () {
    function TokenStoreService() {
        this._storage = localStorage;
        console.log('TokenStoreService created');
        // Try to load the token from the store
        this._token = this._storage.getItem(TOKEN_KEY);
        // Save the current token to the store before the window is unloaded
        window.addEventListener('beforeunload', this.saveTokenToStorage.bind(this));
    }
    Object.defineProperty(TokenStoreService.prototype, "token", {
        get: function () {
            return this._token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TokenStoreService.prototype, "user", {
        get: function () {
            return this.authenticated ? this._user : null;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Gets the originating url from the JW token
     */
    TokenStoreService.prototype.getOriginatingUrl = function () {
        if (this._token) {
            return JWT_HELPER.decodeToken(this._token).ourl;
        }
        return null;
    };
    TokenStoreService.prototype.setToken = function (token, saveToStorage) {
        if (saveToStorage === void 0) { saveToStorage = true; }
        // Set the token
        this._token = token;
        // Set the user
        if (token == null) {
            this._user = null;
            window['currentUserID'] = null;
        }
        else {
            this._user = JWT_HELPER.decodeToken(token);
            window['currentUserID'] = this._user.nameid;
        }
        // Save the token
        if (saveToStorage) {
            this.saveTokenToStorage();
        }
    };
    TokenStoreService.prototype.saveTokenToStorage = function () {
        if (this._token == null) {
            this._storage.removeItem(TOKEN_KEY);
        }
        else {
            this._storage.setItem(TOKEN_KEY, this._token);
        }
    };
    Object.defineProperty(TokenStoreService.prototype, "authenticated", {
        get: function () {
            return !JWT_HELPER.isTokenExpired(this._token);
        },
        enumerable: true,
        configurable: true
    });
    TokenStoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenStoreService_Factory() { return new TokenStoreService(); }, token: TokenStoreService, providedIn: "root" });
    return TokenStoreService;
}());
export { TokenStoreService };
