import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TwoStepAuthenticationService = /** @class */ (function () {
    function TwoStepAuthenticationService(http) {
        this.http = http;
        console.log('TwoStepAuthenticationService created');
    }
    TwoStepAuthenticationService.prototype.loadTwoStepAuthenticationData = function (data) {
        return this.http.get("authentication/" + data.userid + "/" + data.date + "/" + data.token);
    };
    TwoStepAuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TwoStepAuthenticationService_Factory() { return new TwoStepAuthenticationService(i0.ɵɵinject(i1.HttpClient)); }, token: TwoStepAuthenticationService, providedIn: "root" });
    return TwoStepAuthenticationService;
}());
export { TwoStepAuthenticationService };
