import { NgZone } from '@angular/core';
import { bezier } from '@app/_helpers/index';
import * as i0 from "@angular/core";
var AutoScrollService = /** @class */ (function () {
    function AutoScrollService(zone) {
        this.zone = zone;
        // set the cubic bezier animation
        this.easing = bezier(0.4, 0.0, 0.2, 1);
    }
    AutoScrollService.prototype.scrollToAnimation = function (element, config) {
        if (config === void 0) { config = { duration: 400, offsetTop: 0, offsetBottom: 0 }; }
        if (element && element instanceof HTMLElement) {
            this.doScrollAnimation(this.determineScrollTo(element, config), config);
        }
        else {
            this.doScrollAnimation(element, config);
        }
    };
    AutoScrollService.prototype.scrollToAbsolute = function (element, config) {
        if (config === void 0) { config = { duration: 0, offsetTop: 0, offsetBottom: 0 }; }
        if (element && element instanceof HTMLElement) {
            this.doScrollAbsolute(this.determineScrollTo(element, config), config);
        }
        else {
            this.doScrollAbsolute(element, config);
        }
    };
    // do scrolling without animation
    AutoScrollService.prototype.doScrollAbsolute = function (targetScrollTop, options) {
        targetScrollTop += this.getStartScrollPosition();
        window.scrollTo(0, targetScrollTop);
    };
    // do scrolling with animation
    AutoScrollService.prototype.doScrollAnimation = function (targetScrollTop, options) {
        var _this = this;
        // set start time for animation
        var timeStart = Date.now();
        var startScrollTop = this.getStartScrollPosition();
        targetScrollTop += startScrollTop;
        targetScrollTop -= options.offsetTop;
        this.zone.runOutsideAngular(function () {
            var step = function (_targetScrollTop, _options) {
                var elapsed = Date.now() - timeStart;
                // get the new position to scroll to
                var position = _this.getPosition(startScrollTop, _targetScrollTop, elapsed, _options.duration);
                window.scrollTo(0, position);
                if (elapsed <= _options.duration) {
                    requestAnimationFrame(function () { return step(_targetScrollTop, _options); });
                }
            };
            step(targetScrollTop, options);
        });
    };
    // determine the position to scroll to
    AutoScrollService.prototype.determineScrollTo = function (element, options) {
        var rect = element.getBoundingClientRect();
        var margin = parseInt(window.getComputedStyle(element)['marginTop'], 10) + options.offsetTop;
        // only start scrolling if the element is leaving the viewport
        var targetScrollTop = rect.bottom - (window.innerHeight || document.documentElement.clientHeight) + margin;
        if (targetScrollTop < 0) {
            targetScrollTop = 0;
        }
        // if the element is too long to scroll to, scroll to the top of the element.
        if (targetScrollTop + options.offsetBottom > rect.top) {
            targetScrollTop = rect.top - margin;
        }
        else {
            // scroll the element into view with the bottom offset included
            targetScrollTop += options.offsetBottom;
        }
        return targetScrollTop;
    };
    AutoScrollService.prototype.getPosition = function (start, end, elapsed, duration) {
        if (elapsed > duration) {
            return end;
        }
        return start + (end - start) * this.easing(elapsed / duration);
    };
    AutoScrollService.prototype.getStartScrollPosition = function () {
        return window.scrollY || document.documentElement.scrollTop;
    };
    AutoScrollService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AutoScrollService_Factory() { return new AutoScrollService(i0.ɵɵinject(i0.NgZone)); }, token: AutoScrollService, providedIn: "root" });
    return AutoScrollService;
}());
export { AutoScrollService };
