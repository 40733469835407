import { Router } from '@angular/router';
import { UserType } from '@app/_helpers';
import { ConfigService, TokenService } from '@app/_services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/config.service";
import * as i3 from "../_services/token.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router, configService, tokenService) {
        this.router = router;
        this.configService = configService;
        this.tokenService = tokenService;
        console.log('AuthGuard created');
    }
    AuthGuard.prototype.canActivateChild = function (route, state) {
        return this.canActivate(route, state);
    };
    AuthGuard.prototype.canActivate = function (route, state) {
        // Set default user type
        var userTypes = [UserType.Site];
        // Get allowed user type from route data if any
        if (route.data['type']) {
            userTypes = route.data['type'];
        }
        var returnUrl = state.url !== '/' ? state.url.replace(/^\//, '') : null;
        // If authenticated and user type maches
        if (!this.tokenService.authenticated || !userTypes.includes(this.tokenService.user.aud)) {
            // Redirect based on first user type of route
            switch (userTypes[0]) {
                case UserType.STE:
                    this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
                    break;
                case UserType.Waiting:
                    // just go to the login if you are unauthorized
                    this.router.navigate(['/login']);
                    break;
                case UserType.Site:
                    // Redirect to iSync SSO page
                    if (this.configService.config.isync) {
                        this.router.navigate(['/login', 'isync'], { queryParams: { returnUrl: returnUrl } });
                    }
                    else {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
                    }
                    break;
                case UserType.Portfolio:
                    this.router.navigate(['/insight', route.parent.params['id'], 'login'], {
                        queryParams: { returnUrl: returnUrl }
                    });
                    break;
                case UserType.Supervisor:
                    this.router.navigate(['/supervisor', 'login'], { queryParams: { returnUrl: returnUrl } });
                    break;
                case UserType.Progress:
                    this.router.navigate(['/progress', route.params['learnperiodid'] || route.parent.params['learnperiodid'], 'login'], { queryParams: { returnUrl: returnUrl } });
                    break;
            }
            return false;
        }
        var user = this.tokenService.user;
        // Check if logged on portfolio user is the same as the URL
        if (user.aud === UserType.Portfolio && user.nameid !== route.parent.params.id) {
            this.router.navigate(['/insight', route.parent.params.id, 'login']);
            return false;
        }
        // Check if STE user has ticket
        if (user.aud === UserType.STE && user.ticket == null && !state.url.startsWith('/login/activate')) {
            this.router.navigate(['/login', 'activate'], { queryParams: { returnUrl: returnUrl } });
            return false;
        }
        // Allow activate route
        return true;
    };
    AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.TokenService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
