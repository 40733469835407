import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var HttpClientActiveRequestsService = /** @class */ (function () {
    function HttpClientActiveRequestsService() {
        this._activeRequests = [];
        this._activeRequests$ = new BehaviorSubject([]);
        console.log('HttpClientActiveRequests service created');
    }
    HttpClientActiveRequestsService.prototype.add = function (req) {
        this._activeRequests.push(req);
        this._activeRequests$.next(this._activeRequests);
    };
    HttpClientActiveRequestsService.prototype.remove = function (req) {
        var index = this._activeRequests.indexOf(req);
        if (index > -1) {
            this._activeRequests.splice(index, 1);
        }
        this._activeRequests$.next(this._activeRequests);
    };
    HttpClientActiveRequestsService.prototype.requests = function () {
        return this._activeRequests$;
    };
    HttpClientActiveRequestsService.prototype.count = function () {
        return this.requests().pipe(map(function (requests) { return requests.length; }), distinctUntilChanged());
    };
    HttpClientActiveRequestsService.prototype.any = function () {
        return this.count().pipe(map(function (requests) { return requests > 0; }), distinctUntilChanged());
    };
    HttpClientActiveRequestsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpClientActiveRequestsService_Factory() { return new HttpClientActiveRequestsService(); }, token: HttpClientActiveRequestsService, providedIn: "root" });
    return HttpClientActiveRequestsService;
}());
export { HttpClientActiveRequestsService };
