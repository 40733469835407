import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import localeNL from '@angular/common/locales/nl';
import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './theme.service';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./theme.service";
import * as i3 from "./tokenstore.service";
import * as i4 from "@angular/common/http";
var ConfigService = /** @class */ (function () {
    function ConfigService(injector, translate, themeService, tokenStoreService, http) {
        this.injector = injector;
        this.translate = translate;
        this.themeService = themeService;
        this.tokenStoreService = tokenStoreService;
        this.http = http;
        console.log('Config service created');
        // register locale for pipe directives that uses i18n
        // Datepipe, CurrencyPipe, DecimalPipe, PercentPipe
        registerLocaleData(localeNL, 'nl-NL');
        registerLocaleData(localeDE, 'de-DE');
        registerLocaleData(localeFR, 'fr-FR');
    }
    ConfigService.prototype.load = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Setup translate
            _this.translate.addLangs(['en-US', 'nl-NL', 'de-DE', 'fr-FR']);
            _this.translate.setDefaultLang('en-US');
            _this.http.post('config', { url: window.location.hostname }).subscribe(function (config) {
                // Store the config
                _this._config = config;
                // Apply the config on load
                _this.update();
                // Resolve the promise
                resolve();
            });
        });
    };
    Object.defineProperty(ConfigService.prototype, "config", {
        /** Gets the current configuration */
        get: function () {
            return this._config;
        },
        enumerable: true,
        configurable: true
    });
    ConfigService.prototype.update = function (config) {
        if (config === void 0) { config = null; }
        var user = this.tokenStoreService.user;
        var theme = (config && config.theme) || (user ? user.thm || null : this.config.theme || null);
        var language = (config && config.language) || (user && user.lng) || this.config.language;
        // update config language from local confic object, needed to set the language
        this._config.language = language;
        // Set theme
        this.themeService.setTheme(theme);
        // Set language
        if (language != null && this.translate.getLangs().map(function (l) { return l.toLowerCase(); }).includes(language.toLowerCase())) {
            this.translate.use(language);
        }
    };
    ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.ThemeService), i0.ɵɵinject(i3.TokenStoreService), i0.ɵɵinject(i4.HttpClient)); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
