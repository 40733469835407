import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token.service";
import * as i3 from "./tokenstore.service";
var PortfolioService = /** @class */ (function () {
    function PortfolioService(http, tokenService, tokenStoreService) {
        this.http = http;
        this.tokenService = tokenService;
        this.tokenStoreService = tokenStoreService;
        console.log('PortfolioService created');
    }
    PortfolioService.prototype.hasPassword = function (id) {
        return this.http.get("portfolio/" + id + "/haspassword");
    };
    PortfolioService.prototype.setPassword = function (id, password) {
        var _this = this;
        return this.http.post("portfolio/" + id + "/setpassword", password).pipe(map(function (response) {
            // Set new token
            _this.tokenService.setToken(response);
            return true;
        }));
    };
    PortfolioService.prototype.authenticate = function (id, password) {
        var _this = this;
        return this.http.post("portfolio/" + id + "/authenticate", password).pipe(map(function (response) {
            // Set new token
            _this.tokenService.setToken(response);
            return true;
        }));
    };
    PortfolioService.prototype.getUsers = function () {
        return this.http.get("portfolio/users");
    };
    /**
     * Gets the results of a user (as an observable)
     * @param id The id of the user to get the results of
     */
    PortfolioService.prototype.getPortfolioResults = function (id) {
        return this.http.get("portfolio/" + id + "/results");
    };
    PortfolioService.prototype.getAttachment = function (attachmentid) {
        return this.http
            .get("portfolio/attachment/" + attachmentid, {
            observe: 'response',
            responseType: 'blob'
        })
            .pipe(map(function (response) {
            // get the headers' content disposition
            var cd = response.headers.get('content-disposition');
            // get the file name with regex
            var regex = /filename[^;=\n]*=((['"])(.*?)\2|[^;\n]*)/;
            var fileName = regex.exec(cd)[3];
            return {
                blob: response.body,
                filename: fileName
            };
        }));
    };
    PortfolioService.prototype.requestResetPasswordLink = function (id) {
        return this.http.get("portfolio/" + id + "/requestresetpasswordlink");
    };
    PortfolioService.prototype.passwordResetTokenExpired = function (passwordResetToken) {
        return this.http.get("portfolio/resetpasswordtokenexpired/" + passwordResetToken);
    };
    PortfolioService.prototype.resetPassword = function (passwordResetToken, password) {
        var _this = this;
        return this.http.post("portfolio/resetpassword/" + passwordResetToken, password).pipe(map(function (response) {
            _this.tokenService.setToken(response);
        }));
    };
    PortfolioService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortfolioService_Factory() { return new PortfolioService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenService), i0.ɵɵinject(i3.TokenStoreService)); }, token: PortfolioService, providedIn: "root" });
    return PortfolioService;
}());
export { PortfolioService };
