import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageType } from '@app/_helpers';
import { TranslateService } from '@ngx-translate/core';
import { QueueingSubject } from 'queueing-subject';
import { BehaviorSubject, Subject } from 'rxjs';
import { default as makeWebSocketObservable } from 'rxjs-websockets';
import { delay, filter, map, retryWhen, share, switchMap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ConfigService } from './config.service';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "./tokenstore.service";
import * as i2 from "./config.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "./alert.service";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
var SESSIONPARAMETERPREFIX = 'session_';
var TokenService = /** @class */ (function () {
    function TokenService(tokenStoreService, configService, http, router, alertService, location, translateService) {
        var _this = this;
        this.tokenStoreService = tokenStoreService;
        this.configService = configService;
        this.http = http;
        this.router = router;
        this.alertService = alertService;
        this.location = location;
        this.translateService = translateService;
        this._sessionMessages = new Subject();
        this._loggingOff = false;
        this.token$ = new BehaviorSubject(null);
        console.log('TokenService created');
        // Set the token from the store
        this.setToken(tokenStoreService.token, false);
        // Subscribe on alerts
        this._sessionMessages.pipe(filter(function (m) { return m.type === MessageType.Alert; })).subscribe(function (m) {
            _this.alertService.success(m.tag, true);
        });
        // Subscribe to logoff events
        this._sessionMessages.pipe(filter(function (m) { return m.type === MessageType.Logoff; })).subscribe(function (m) {
            // show logoff message
            _this.translateService.get('LOGGEDOFFBYSUPERVISOR').subscribe(function (translation) {
                return _this.alertService.success(translation, true);
            });
            _this.logoutAndReload();
        });
    }
    Object.defineProperty(TokenService.prototype, "user", {
        get: function () {
            return this.tokenStoreService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TokenService.prototype, "messages", {
        get: function () {
            return this._sessionMessages;
        },
        enumerable: true,
        configurable: true
    });
    TokenService.getSessionParameter = function (key) {
        var item = sessionStorage.getItem("" + SESSIONPARAMETERPREFIX + key);
        if (item) {
            return JSON.parse(item);
        }
    };
    TokenService.setSessionParameter = function (key, data) {
        sessionStorage.setItem("" + SESSIONPARAMETERPREFIX + key, JSON.stringify(data));
    };
    TokenService.prototype.send = function (key, data) {
        this._sessionInput.next(JSON.stringify({ key: key, data: data }));
    };
    TokenService.prototype.authenticate = function (username, password, locationId) {
        if (locationId === void 0) { locationId = null; }
        return this.logon('authenticate', { username: username, password: password, locationId: locationId });
    };
    TokenService.prototype.authenticateSupervisor = function (username, password, locationId) {
        if (locationId === void 0) { locationId = null; }
        return this.logon('supervisor/authenticate', { username: username, password: password, locationId: locationId });
    };
    TokenService.prototype.authenticateProgress = function (password, learnperiodid) {
        return this.logon("progress/" + learnperiodid + "/authenticate", password);
    };
    TokenService.prototype.authenticateToken = function (tokenId) {
        return this.logon('authenticate/token', tokenId);
    };
    TokenService.prototype.authenticateUpgradeToken = function () {
        return this.logon('authenticate/upgradetoken', null);
    };
    TokenService.prototype.authenticateRefreshToken = function () {
        return this.logon('authenticate/refreshtoken', null);
    };
    TokenService.prototype.authenticateMoveToSTE = function (secureLocationId) {
        var originatingUrl = location.href;
        return this.http.post('authenticate/movetoste', { secureLocationId: secureLocationId, originatingUrl: originatingUrl });
    };
    TokenService.prototype.activateTicket = function (ticketCode) {
        return this.logon('authenticate/activate', ticketCode);
    };
    TokenService.prototype.authenticateSync = function (data) {
        return this.logon('authenticate/isync', data);
    };
    TokenService.prototype.registerUser = function (username, learnperiodid, usergroupid) {
        return this.logon("register/registeruser/" + learnperiodid + "/" + usergroupid, username);
    };
    TokenService.prototype.logon = function (url, body) {
        var _this = this;
        // Map and share request
        var request = this.http.post(url, body).pipe(share());
        // Subscribe to response to set token
        request.subscribe(function (token) {
            _this.setToken(token);
        }, function (error) { return error; });
        return request;
    };
    Object.defineProperty(TokenService.prototype, "authenticated", {
        get: function () {
            return this.tokenStoreService.authenticated;
        },
        enumerable: true,
        configurable: true
    });
    TokenService.prototype.logout = function () {
        if (this._loggingOff)
            return;
        this._loggingOff = true;
        // Delete authentication
        if (this.authenticated) {
            this.http.delete('authenticate').subscribe();
        }
        var originatingUrl = this.configService.config.ste ? this.tokenStoreService.getOriginatingUrl() : null;
        // Clear token
        this.setToken(null);
        // Reset session parameters
        Object.keys(sessionStorage)
            .filter(function (key) { return key.indexOf(SESSIONPARAMETERPREFIX) === 0; })
            .forEach(function (key) { return sessionStorage.removeItem(key); });
        if (originatingUrl) {
            location.href = originatingUrl;
        }
        this._loggingOff = false;
    };
    TokenService.prototype.setToken = function (token, saveToStorage) {
        if (saveToStorage === void 0) { saveToStorage = true; }
        // Set the token in the storage
        this.tokenStoreService.setToken(token, saveToStorage);
        // Handle session socket
        if (this.tokenStoreService.token != null && this.tokenStoreService.authenticated) {
            this.connect(token);
        }
        else if (this._sessionWebSocket) {
            this.disconnect();
        }
        this.token$.next(token);
        // Set config
        this.configService.update();
    };
    TokenService.prototype.connect = function (token) {
        var _this = this;
        // Disconnect if already exists
        if (this._sessionWebSocket) {
            this.disconnect();
        }
        // Make url
        var protocol = location.protocol === 'https:' ? 'wss' : 'ws';
        var sessionUrl = protocol + "://" + window.location.hostname + "/api/session";
        // Create new input stream
        this._sessionInput = new QueueingSubject();
        // Create websocket
        var defaultWebsocketFactory = function (url, protocols) {
            return new WebSocket(sessionUrl, token);
        };
        // abuse protocol parameter for sending token
        this._sessionWebSocket = makeWebSocketObservable(sessionUrl, {
            protocols: protocol,
            makeWebSocket: defaultWebsocketFactory
        });
        if (this._socketSubscription)
            this._socketSubscription.unsubscribe();
        // Publish session messages from connection session connection
        this._socketSubscription = this._sessionWebSocket.pipe(switchMap(function (getResponses) {
            return getResponses(_this._sessionInput.pipe(map(function (request) { return JSON.stringify(request); }))).pipe(map(function (resp) { return JSON.parse(resp); }));
        }), retryWhen(function (errors) { return errors.pipe(delay(1000)); }), filter(function (message) { return !!message; })).subscribe(function (message) {
            _this._sessionMessages.next(message);
        });
        window.taStopSocket = function () { _this.disconnect(); };
        // Dummy Test Message
        this.send('dummy', "A-Team says hi!!!!");
    };
    /** logs of the user and reloads the page (to let the authguard handle what should happen) */
    TokenService.prototype.logoutAndReload = function () {
        var _this = this;
        if (this._loggingOff)
            return;
        this._loggingOff = true;
        // Clear token
        this.setToken(null);
        // Get path to reload
        var path = this.location.path();
        // Reload current url
        this.router.navigateByUrl('/reload', { replaceUrl: true }).then(function () {
            _this._loggingOff = false;
            // let authguard handle what should happen
            _this.router.navigateByUrl(path, { replaceUrl: true });
        });
    };
    TokenService.prototype.disconnect = function () {
        // Close socket (this will complete all subscribers)
        if (this._sessionWebSocket) {
            this._sessionWebSocket = null;
            if (this._socketSubscription)
                this._socketSubscription.unsubscribe();
        }
        // Reset observables
        this._sessionInput = null;
    };
    TokenService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenService_Factory() { return new TokenService(i0.ɵɵinject(i1.TokenStoreService), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.AlertService), i0.ɵɵinject(i6.Location), i0.ɵɵinject(i7.TranslateService)); }, token: TokenService, providedIn: "root" });
    return TokenService;
}());
export { TokenService };
