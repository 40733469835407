import { ConfigService } from '@app/_services';
var AboutDialogComponent = /** @class */ (function () {
    function AboutDialogComponent(configService) {
        this.configService = configService;
    }
    Object.defineProperty(AboutDialogComponent.prototype, "aboutTitle", {
        /** Gets the title of the 'about' dialog */
        get: function () {
            return this.configService.config.abouttitle;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AboutDialogComponent.prototype, "aboutText", {
        /** Gets the text of the 'about' dialog */
        get: function () {
            return this.configService.config.abouttext;
        },
        enumerable: true,
        configurable: true
    });
    return AboutDialogComponent;
}());
export { AboutDialogComponent };
