import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ITitleService } from '@app/_interfaces/ititleservice';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TitleService implements ITitleService
{
    private title: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private titleService: Title, private translate: TranslateService)
    {
        // translate title on language change
        this.translate.onLangChange.subscribe(() =>
        {
            this.setTitle(this.title.value);
        });

        this.getTitle()
            .pipe(
                filter(term => !!term),
                switchMap((title) => this.translate.get(title))
            )
            .subscribe(title => this.titleService.setTitle(title));
    }

    /** Gets a subscribable for the title */
    public getTitle(): BehaviorSubject<string>
    {
        return this.title;
    }

    /**
     * Sets a new title
     * @param title The new title to set
     */
    public setTitle(title?: string): void
    {
        return this.title.next(title);
    }
}
