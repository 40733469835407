import { RouterModule } from '@angular/router';
import { MessagePageComponent } from '@app/message-page/message-page.component';
import { TokenComponent } from '@app/token/token.component';
import { AuthGuard } from '@app/_guards';
import { UserType } from '@app/_helpers';
import { of } from 'rxjs';
var CustomPreloadStrategy = /** @class */ (function () {
    function CustomPreloadStrategy() {
    }
    CustomPreloadStrategy.prototype.preload = function (route, fn) {
        return route.data && route.data.preload ? fn() : of(null);
    };
    return CustomPreloadStrategy;
}());
export { CustomPreloadStrategy };
var ɵ0 = { type: [UserType.Site, UserType.STE, UserType.Exam, UserType.Preview], preload: true }, ɵ1 = { type: [UserType.Waiting] }, ɵ2 = { type: [UserType.Site, UserType.STE] };
var appRoutes = [
    { path: 'login', loadChildren: 'app/login/login.module#LoginModule' /** matcher: loginUrlMatcher, */ },
    {
        path: 'learnmaterial/:id',
        loadChildren: 'app/learnmaterial/learnmaterial.module#LearnmaterialModule',
        canActivate: [AuthGuard],
        data: ɵ0
    },
    { path: 'insight/:id', loadChildren: 'app/portfolio/portfolio.module#PortfolioModule' },
    {
        path: 'twostepauthentication/:userid/:date/:token',
        loadChildren: 'app/twostepauthentication/twostepauthentication.module#TwoStepAuthenticationModule'
    },
    { path: 'supervisor', loadChildren: 'app/supervisor/supervisor.module#SupervisorModule' },
    { path: 'token/:id', component: TokenComponent },
    {
        path: 'register/local/:learnmaterialid/:usergroupid',
        loadChildren: 'app/register/register.module#RegisterModule'
    },
    { path: 'progress/:learnperiodid', loadChildren: 'app/progress/progress.module#ProgressModule' },
    { path: 'training/:trainingid', loadChildren: 'app/public-training/public-training.module#PublicTrainingModule' },
    {
        path: 'waitingroom',
        loadChildren: 'app/waitingroom/waitingroom.module#WaitingroomModule',
        canActivate: [AuthGuard],
        data: ɵ1
    },
    {
        path: '',
        loadChildren: 'app/site/site.module#SiteModule',
        canActivate: [AuthGuard],
        data: ɵ2
    },
    { path: '**', component: MessagePageComponent }
    //// otherwise redirect to root
    // { path: '**', redirectTo: '' }
];
export var routing = RouterModule.forRoot(appRoutes, {
    preloadingStrategy: CustomPreloadStrategy,
    onSameUrlNavigation: 'reload'
});
var CustomRouteReuseStrategy = /** @class */ (function () {
    function CustomRouteReuseStrategy() {
    }
    CustomRouteReuseStrategy.prototype.shouldDetach = function (route) {
        return false;
    };
    CustomRouteReuseStrategy.prototype.store = function (route, detachedTree) { };
    CustomRouteReuseStrategy.prototype.shouldAttach = function (route) {
        return false;
    };
    CustomRouteReuseStrategy.prototype.retrieve = function (route) {
        return null;
    };
    CustomRouteReuseStrategy.prototype.shouldReuseRoute = function (future, curr) {
        if (future.routeConfig && future.routeConfig.data && future.routeConfig.data.doNotReuse) {
            delete future.routeConfig.data.doNotReuse;
            return false;
        }
        return future.routeConfig === curr.routeConfig;
    };
    return CustomRouteReuseStrategy;
}());
export { CustomRouteReuseStrategy };
export { ɵ0, ɵ1, ɵ2 };
