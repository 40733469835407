import { Config, SecureLocationConfig } from '@app/_models';

export namespace Mocking
{
    export function createMockedConfig(): Config
    {
        return {
            abouttitle: null,
            abouttext: null,
            organisation: null,
            theme: null,
            language: null,
            changepassword: false,
            saml: false,
            logintext: null,
            logo: null,
            abouticon: null,
            minimumpasswordlength: 0,
            ste: false,
            isync: false,
            allsecurelocations: null,
            globalsecurelocations: null,
            localsecurelocations: null
        };
    }

    export function createMockedSecureLocationConfig(): SecureLocationConfig
    {
        return {
            abouttitle: null,
            abouttext: null,
            organisation: null,
            theme: null,
            language: null,
            changepassword: false,
            saml: false,
            logintext: null,
            logo: null,
            abouticon: null,
            minimumpasswordlength: 0,
            location: null,
            locationId: null
        };
    }
}
