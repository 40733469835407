import { Component } from '@angular/core';
@Component({
    selector: 'app-connection-dialog',
    templateUrl: './connection-dialog.component.html',
    styleUrls: ['./connection-dialog.component.scss']
})
export class ConnectionDialogComponent
{

    constructor() { }
}
