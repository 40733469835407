import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AlertService, HttpClientActiveRequestsService, TokenService } from '@app/_services';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ErrorHandlingCode } from './_helpers';

@Injectable()
export class UrlInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler)
    {
        // If request url relative add default prefix
        if (!/^(https?:\/\/|\/)/.test(req.url))
        {
            return next.handle(req.clone({ url: `${environment.apiUrl}${req.url}` }));
        }

        return next.handle(req);
    }
}

@Injectable()
export class ActiveRequestsInterceptor implements HttpInterceptor
{
    constructor(private activeRequests: HttpClientActiveRequestsService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler)
    {
        // Add the request to the active requests
        this.activeRequests.add(req);

        return next.handle(req).pipe(
            tap(
                event =>
                {
                    // Remove from the active requests if the event is for http response
                    if (event instanceof HttpResponse)
                    {
                        this.activeRequests.remove(req);
                    }
                },
                () =>
                {
                    // Remove request on error
                    this.activeRequests.remove(req);
                },
                () =>
                {
                    // Remove request on complete
                    this.activeRequests.remove(req);
                }
            )
        );
    }
}

@Injectable()
export class BodyInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler)
    {
        // Convert string to JSON and add contenttype header if body is type of string
        if (typeof req.body === 'string')
        {
            return next.handle(
                req.clone({
                    body: JSON.stringify(req.body),
                    headers: req.headers.set('Content-Type', 'application/json')
                })
            );
        }

        return next.handle(req);
    }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor
{
    constructor(private alertService: AlertService, private translate: TranslateService, private _injector: Injector) { }

    /**
     * Checks what handler the error should be handled by
     * @param error The error that occurred
     **/
    private setErrorHandledBy(error: any): ErrorHandlingCode
    {
        if (error.url)
        {
            const url: string = error.url.toLowerCase();

            if (error.status === 0 && url.indexOf('/learnmaterial/') > -1 && (url.indexOf('/update/') > -1 || url.indexOf('/close') > -1))
                return ErrorHandlingCode.UnknownErrorHandler;
            else if (error.status === 404 && url.indexOf('/learnmaterial/') > -1 && (url.indexOf('/update/') > -1))
                return ErrorHandlingCode.TimeIsUpErrorHandler;
        }

        return ErrorHandlingCode.Interceptor;
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler)
    {
        return next.handle(req).pipe(
            catchError((error: any) =>
            {
                error.handledBy = this.setErrorHandledBy(error);

                if (error.handledBy === ErrorHandlingCode.Interceptor)
                {
                    if (error instanceof HttpErrorResponse)
                    {
                        if (error.error != null && typeof error.error.reason === 'string')
                        {
                            // Alert the translation of the reason
                            this.alertReason(error.error.reason);
                        } else if (error.error != null && typeof error.error.Id === 'string')
                        {
                            // Alert unexpected error with ID
                            this.alertMessage(`An unexpected error occured with ID: ${error.error.Id}`);

                        } else if (
                            error.error != null &&
                            error.error instanceof Blob &&
                            error.error.type === 'application/json'
                        )
                        {
                            // Read JSON blob
                            const reader = new FileReader();
                            reader.addEventListener('load', event =>
                            {
                                let data: any;

                                // Try to read the data from the event
                                try
                                {
                                    data = JSON.parse(<string>(<FileReader>event.target).result);
                                } catch {
                                    this.alertUnexpectedError(error.message);
                                }

                                // // Alert the translation of the reason
                                if (data != null && typeof data.reason === 'string')
                                {
                                    this.alertReason(data.reason);

                                    // If expected catch the error by returning an empty observable
                                    if (data.expected === true)
                                    {
                                        return EMPTY;
                                    }
                                } else
                                {
                                    this.alertUnexpectedError(error.message);
                                }
                            });

                            reader.addEventListener('error', () =>
                            {
                                this.alertUnexpectedError(error.message);
                            });

                            reader.readAsText(error.error);
                        }
                        else
                        {
                            // Alert unexpected error with message
                            if (error.status !== 401)
                                this.alertUnexpectedError(error.message);
                        }
                    }
                    else
                    {
                        // Alert the raw error
                        this.alertUnexpectedError(JSON.stringify(error));
                    }

                    // if the error should cause the user to be logged out
                    if ((!error.error && error.status === 401) || (error.error != null && error.error.logout === true))
                    {
                        this._injector.get(TokenService).logoutAndReload();
                        if (error.status === 401)
                            return;
                    }

                    // Add the original request to the error for logging
                    error.request = req;
                }

                // Propagate the error for error log
                return throwError(error);
            })
        );
    }

    alertReason(reason: string)
    {
        // Alert the translation of the reason
        this.translate.get(`ALERT.${reason.toUpperCase()}`).subscribe(message => this.alertMessage(message));
    }

    alertUnexpectedError(error: string)
    {
        this.alertMessage(`An unexpected error occured: ${error}`);
    }

    alertMessage(message: string)
    {
        this.alertService.error(message, true);
    }
}
