import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';
var MessagePageComponent = /** @class */ (function () {
    function MessagePageComponent(app, activatedRoute, translate) {
        this.app = app;
        this.activatedRoute = activatedRoute;
        this.translate = translate;
        // Set background
        app.background = true;
    }
    Object.defineProperty(MessagePageComponent.prototype, "notReloading", {
        /** gets whether this is not the reloading page. If it is the reloading page,
         * basically, nothing should happen.
         */
        get: function () {
            return this.activatedRoute.snapshot.url.length === 0 ||
                this.activatedRoute.snapshot.url[0].path !== 'reload';
        },
        enumerable: true,
        configurable: true
    });
    MessagePageComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.notReloading) {
            var url = this.activatedRoute.snapshot.url;
            var translate = function (tag) {
                return _this.translate.get([tag + ".TITLE", tag + ".MESSAGE"]).pipe(filter(function (result) { return Object.keys(result).every(function (_tag) { return result[_tag] !== _tag; }); }), map(function (result) {
                    return ({
                        title: result[tag + ".TITLE"],
                        message: result[tag + ".MESSAGE"]
                    });
                }));
            };
            // Try to get the translation of the first path segment if only 1 exists
            if (url.length === 1) {
                translate(url[0].path.toUpperCase()).subscribe(function (result) {
                    _this.translation = result;
                });
            }
            // If no translation set get the default page not found translation
            if (this.translation == null) {
                translate('PAGENOTFOUND').subscribe(function (result) {
                    _this.translation = result;
                });
            }
        }
    };
    return MessagePageComponent;
}());
export { MessagePageComponent };
