import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./tokenstore.service";
var UserDataService = /** @class */ (function () {
    function UserDataService(http, tokenStoreService) {
        this.http = http;
        this.tokenStoreService = tokenStoreService;
        console.log('UserDataService created');
    }
    UserDataService.prototype.getMyCoursesData = function () {
        return this.http.get('userdata/mycoursesdata');
    };
    /**
     * Gets the results for a user. This includes results the user added themselves.
     */
    UserDataService.prototype.getResults = function () {
        return this.http.get('userdata/results');
    };
    UserDataService.prototype.getSubjectScores = function (id, attempt) {
        return this.http.get("userdata/subjectscores/" + id + "/" + attempt);
    };
    UserDataService.prototype.addResult = function (result) {
        return this.http.post('userdata/results/add', result);
    };
    /**
     * Updates a result with a new title, description data and added and removed attachments
     * @param result The new result data
     */
    UserDataService.prototype.updateResult = function (result) {
        return this.http.put('userdata/results/update', result);
    };
    UserDataService.prototype.addAttachment = function (formData) {
        return this.http.post('userdata/results/add/certificate', formData, {
            reportProgress: true,
            observe: 'events',
        });
    };
    UserDataService.prototype.getAttachment = function (attachmentID) {
        return this.http
            .get("userdata/results/attachment/" + attachmentID, { observe: 'response', responseType: 'blob' })
            .pipe(map(function (response) {
            // get the headers' content disposition
            var cd = response.headers.get('content-disposition');
            // get the file name with regex
            var regex = /filename=['"]?([^'";]+)['"]?/;
            var fileName = regex.exec(cd)[1];
            return {
                blob: response.body,
                filename: fileName,
            };
        }));
    };
    /**
     * Deletes one attachment
     * @param attachmentName The name of the attachment to delete
     */
    UserDataService.prototype.deleteAttachment = function (attachmentName) {
        return this.http.post('userdata/results/delete/certificate', attachmentName);
    };
    UserDataService.prototype.deleteResult = function (id) {
        return this.http.post('userdata/results/delete', id);
    };
    UserDataService.prototype.getSettings = function () {
        return this.http.get('userdata/settings');
    };
    UserDataService.prototype.setSettings = function (settings) {
        return this.http.post('userdata/settings/update', settings);
    };
    /**
     * Attempts to set a new password
     * @param newPassword The new password to set
     * @param currentPassword The current password, for verification
     */
    UserDataService.prototype.setPassword = function (data) {
        return this.http.post('userdata/settings/update/password', data);
    };
    UserDataService.prototype.getPortfolios = function () {
        return this.http.get('userdata/portfolio');
    };
    UserDataService.prototype.addPortfolio = function (data) {
        return this.http.post('userdata/portfolio/add', data);
    };
    UserDataService.prototype.deletePortfolio = function (id) {
        return this.http.post('userdata/portfolio/delete', id);
    };
    UserDataService.prototype.getSubscriptions = function (subscriptionpageid) {
        return this.http.get("userdata/subscription/" + subscriptionpageid);
    };
    UserDataService.prototype.subscribe = function (subscriptionpageid) {
        return this.http.post('userdata/subscribe', subscriptionpageid);
    };
    UserDataService.prototype.requestResetPasswordLink = function (data) {
        return this.http.post('userdata/requestresetpasswordlink', data);
    };
    UserDataService.prototype.canResetPassword = function (userid, resetpasswordid) {
        return this.http.get("userdata/canresetpassword/" + userid + "/" + resetpasswordid);
    };
    UserDataService.prototype.resetPassword = function (userid, resetpasswordid, password) {
        return this.http.post("userdata/resetpassword/" + userid + "/" + resetpasswordid, password);
    };
    UserDataService.prototype.getPrivacyPolicy = function () {
        return this.http.get("userdata/privacypolicy");
    };
    UserDataService.prototype.setUserLanguage = function (data) {
        return this.http.post('userdata/language/update', data);
    };
    UserDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserDataService_Factory() { return new UserDataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenStoreService)); }, token: UserDataService, providedIn: "root" });
    return UserDataService;
}());
export { UserDataService };
