import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@ngx-translate/core";
var TitleService = /** @class */ (function () {
    function TitleService(titleService, translate) {
        var _this = this;
        this.titleService = titleService;
        this.translate = translate;
        this.title = new BehaviorSubject(null);
        // translate title on language change
        this.translate.onLangChange.subscribe(function () {
            _this.setTitle(_this.title.value);
        });
        this.getTitle()
            .pipe(filter(function (term) { return !!term; }), switchMap(function (title) { return _this.translate.get(title); }))
            .subscribe(function (title) { return _this.titleService.setTitle(title); });
    }
    /** Gets a subscribable for the title */
    TitleService.prototype.getTitle = function () {
        return this.title;
    };
    /**
     * Sets a new title
     * @param title The new title to set
     */
    TitleService.prototype.setTitle = function (title) {
        return this.title.next(title);
    };
    TitleService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TitleService_Factory() { return new TitleService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i2.TranslateService)); }, token: TitleService, providedIn: "root" });
    return TitleService;
}());
export { TitleService };
