import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { SecureLocationConfig } from '@app/_models';
import { ConfigService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './select-secure-location-dialog.component.html',
    styleUrls: ['./select-secure-location-dialog.component.scss']
})
export class SelectSecureLocationDialogComponent
{
    secureLocations: SecureLocationConfig[];

    constructor(
        public dialogRef: MatDialogRef<SelectSecureLocationDialogComponent>,
        private configService: ConfigService,
        private translate: TranslateService
    )
    {
        // Set the available secure locations
        this.secureLocations = this.configService.config.localsecurelocations;

        // Sort the locations by name and then by location
        this.secureLocations.sort(
            (a, b) => a.organisation.localeCompare(b.organisation) || a.location.localeCompare(b.location)
        );
    }
}
