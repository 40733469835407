import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    constructor(@Inject(DOCUMENT) protected _document: Document) {}

    loadScript(id: string, url: string) {
        // Check arguments
        if (!id || !url) {
            throw new Error(`id and url arguments cannot be empty`);
        }

        return new Observable<string>((observer: Observer<string>) => {
            if (this._document.querySelector<HTMLScriptElement>(`script[id="${id}"][src="${url}"]`)) {
                // Complete if script already loaded
                observer.next(url);
                observer.complete();
            } else {
                // Load the script
                const script = this._document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;

                script.onload = () => {
                    observer.next(url);
                    observer.complete();
                };

                script.onerror = (error: any) => {
                    observer.error(`Couldn't load script ${url}`);
                };

                this._document.body.appendChild(script);
            }
        });
    }
}
