import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { UserType } from '@app/_helpers';
import { ConfigService, TokenService } from '@app/_services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild
{
    constructor(private router: Router, private configService: ConfigService, private tokenService: TokenService)
    {
        console.log('AuthGuard created');
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        return this.canActivate(route, state);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    {
        // Set default user type
        let userTypes = [UserType.Site];

        // Get allowed user type from route data if any
        if (route.data['type'])
        {
            userTypes = route.data['type'];
        }

        const returnUrl = state.url !== '/' ? state.url.replace(/^\//, '') : null;

        // If authenticated and user type maches
        if (!this.tokenService.authenticated || !userTypes.includes(this.tokenService.user.aud))
        {
            // Redirect based on first user type of route
            switch (userTypes[0])
            {
                case UserType.STE:
                    this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
                    break;

                case UserType.Waiting:
                    // just go to the login if you are unauthorized
                    this.router.navigate(['/login']);
                    break;

                case UserType.Site:
                    // Redirect to iSync SSO page
                    if (this.configService.config.isync)
                    {
                        this.router.navigate(['/login', 'isync'], { queryParams: { returnUrl: returnUrl } });
                    } else
                    {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });
                    }

                    break;

                case UserType.Portfolio:
                    this.router.navigate(['/insight', route.parent.params['id'], 'login'], {
                        queryParams: { returnUrl: returnUrl }
                    });
                    break;

                case UserType.Supervisor:
                    this.router.navigate(['/supervisor', 'login'], { queryParams: { returnUrl: returnUrl } });
                    break;

                case UserType.Progress:
                    this.router.navigate(
                        ['/progress', route.params['learnperiodid'] || route.parent.params['learnperiodid'], 'login'],
                        { queryParams: { returnUrl: returnUrl } }
                    );
                    break;
            }

            return false;
        }

        const user = this.tokenService.user;

        // Check if logged on portfolio user is the same as the URL
        if (user.aud === UserType.Portfolio && user.nameid !== route.parent.params.id)
        {
            this.router.navigate(['/insight', route.parent.params.id, 'login']);
            return false;
        }

        // Check if STE user has ticket
        if (user.aud === UserType.STE && user.ticket == null && !state.url.startsWith('/login/activate'))
        {
            this.router.navigate(['/login', 'activate'], { queryParams: { returnUrl: returnUrl } });
            return false;
        }

        // Allow activate route
        return true;
    }
}
