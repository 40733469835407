import { Component, OnInit } from '@angular/core';
import { UserDataService } from '@app/_services';
@Component({
    selector: 'app-privacy-policy-dialog',
    templateUrl: './privacy-policy-dialog.component.html',
    styleUrls: ['./privacy-policy-dialog.component.scss']
})
export class PrivacyPolicyDialogComponent implements OnInit {
    text = '';

    constructor(private userDataService: UserDataService) {}

    ngOnInit() {
        // Get the privacy policy text
        this.userDataService.getPrivacyPolicy().subscribe(text => {
            this.text = text;
        });
    }
}
