import { Component } from '@angular/core';
import { ConfigService } from '@app/_services';
@Component({
    selector: 'app-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss']
})
export class AboutDialogComponent
{

    /** Gets the title of the 'about' dialog */
    public get aboutTitle(): string
    {
        return this.configService.config.abouttitle;
    }

    /** Gets the text of the 'about' dialog */
    public get aboutText(): string
    {
        return this.configService.config.abouttext;
    }

    constructor(private configService: ConfigService) { }
}
