import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token.service";
var SupervisorService = /** @class */ (function () {
    function SupervisorService(http, tokenService) {
        this.http = http;
        this.tokenService = tokenService;
        console.log('SupervisorService created');
        this._selectionCount$ = new BehaviorSubject(0);
    }
    SupervisorService.prototype.selectionCount = function () {
        return this._selectionCount$;
    };
    /**
     * Update the selection count
     * @param value new
     */
    SupervisorService.prototype.updateSelectionCount = function (value) {
        this._selectionCount$.next(value);
    };
    SupervisorService.prototype.getSecureLocations = function () {
        return this.http.get('supervisor/securelocations');
    };
    SupervisorService.prototype.setSecureLocation = function (locationId) {
        var _this = this;
        return this.http.post('supervisor/securelocation', locationId).pipe(map(function (token) {
            _this.tokenService.setToken(token);
        }));
    };
    SupervisorService.prototype.activateUsers = function (userids) {
        return this.http.post('supervisor/activateUsers', userids);
    };
    SupervisorService.prototype.activateTicketcode = function (ticketcode) {
        return this.http.post('supervisor/activateticketcode', ticketcode);
    };
    SupervisorService.prototype.closeExams = function (userids) {
        return this.http.post('supervisor/closeExams', userids);
    };
    SupervisorService.prototype.deactivateUsers = function (userids) {
        return this.http.post('supervisor/deactivateUsers', userids);
    };
    SupervisorService.prototype.getUsers = function () {
        return this.http.get('supervisor/users');
    };
    /**
     * Get the learnperiods for the securelocation
     * @param secureLocationId secure location id
     */
    SupervisorService.prototype.getFilters = function (secureLocationId) {
        return this.http.get("supervisor/filterdata/" + secureLocationId);
    };
    SupervisorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SupervisorService_Factory() { return new SupervisorService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenService)); }, token: SupervisorService, providedIn: "root" });
    return SupervisorService;
}());
export { SupervisorService };
