// https://github.com/angular/material2/issues/13588#issuecomment-469035560
var AutoScrollOnDrag = /** @class */ (function () {
    function AutoScrollOnDrag(container, scrollCallback) {
        this.container = container;
        this.scrollCallback = scrollCallback;
        /**
         * The margin when the autoscroll starts scrolling
         */
        this._margin = 50;
        /**
         * The speed of the scroll
         */
        this._maxSpeed = 25;
        /**
         * Gets or Sets if the window object needs to be scrolled instead of the scrollable container
         */
        this.useWindowAsScrollableContainer = false;
        /**
         * The position where the mouse is on screen
         */
        this._point = { x: 0, y: 0 };
        this._scrollTicks = 0;
        this.scrollableContainer = container;
        this._boundaryRect = this.container.getBoundingClientRect();
    }
    Object.defineProperty(AutoScrollOnDrag.prototype, "scrollableContainer", {
        get: function () {
            return this._scrollableContainer;
        },
        set: function (container) {
            this._scrollableContainer = container;
            this._boundaryRect = this.container.getBoundingClientRect();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AutoScrollOnDrag.prototype, "boundaryRect", {
        set: function (value) {
            this._boundaryRect = value;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Handles the move of the scroll action
     * @param {{ x: number; y: number }} point The point where the mouse is
     * @memberof AutoScroll
     */
    AutoScrollOnDrag.prototype.onMove = function (point) {
        var _this = this;
        this._point = point;
        cancelAnimationFrame(this._animationFrame);
        this._animationFrame = requestAnimationFrame(function () { return _this.scrollTick(); });
    };
    /**
     * Do the next scroll action
     * @private
     * @memberof AutoScroll
     */
    AutoScrollOnDrag.prototype.scrollTick = function () {
        var _this = this;
        cancelAnimationFrame(this._animationFrame);
        if (this.autoScroll()) {
            this._animationFrame = requestAnimationFrame(function () { return _this.scrollTick(); });
        }
        else {
            this._scrollTicks = 0;
        }
    };
    /**
     * Checks if scrolling is possible and scrolls the container
     * @private
     * @returns {boolean}
     * @memberof AutoScroll
     */
    AutoScrollOnDrag.prototype.autoScroll = function () {
        var _this = this;
        var scrolly;
        this._scrollTicks = (this._scrollTicks + 1) % 15;
        if (this._point.y < this._boundaryRect.top + this._margin) {
            scrolly = Math.floor(Math.max(-1, (this._point.y - this._boundaryRect.top) / this._margin - 1) * this._maxSpeed);
        }
        else if (this._point.y > this._boundaryRect.bottom - this._margin) {
            scrolly = Math.ceil(Math.min(1, (this._point.y - this._boundaryRect.bottom) / this._margin + 1) * this._maxSpeed);
        }
        else {
            scrolly = 0;
        }
        setTimeout(function () {
            if (scrolly) {
                _this.scrollY(scrolly);
            }
        });
        return scrolly !== 0;
    };
    /**
     * Scrolls the Y-axis
     *
     * @private
     * @param {number} amount
     * @memberof AutoScroll
     */
    AutoScrollOnDrag.prototype.scrollY = function (amount) {
        if (this.useWindowAsScrollableContainer)
            window.scrollTo(0, window.scrollY + amount);
        else
            this.container.scrollTop += amount;
        if (this._scrollTicks === 0) {
            this.doCallback();
        }
    };
    AutoScrollOnDrag.prototype.doCallback = function () {
        if (this.scrollCallback) {
            this.scrollCallback();
        }
    };
    AutoScrollOnDrag.prototype.destroy = function () {
        cancelAnimationFrame(this._animationFrame);
    };
    return AutoScrollOnDrag;
}());
export { AutoScrollOnDrag };
