// https://github.com/angular/material2/issues/13588#issuecomment-469035560
export class AutoScrollOnDrag {

    /**
	 * The margin when the autoscroll starts scrolling
	 */
    private _margin: number = 50;
    /**
	 * The speed of the scroll
	 */
    private _maxSpeed: number = 25;
    /**
	 * The frame when the scroll takes place
	 */
    private _animationFrame: number;
    /**
	 * The boundary of the scroll container
	 */
    private _boundaryRect: ClientRect;

    /**
     * Gets or Sets if the window object needs to be scrolled instead of the scrollable container
     */
    public useWindowAsScrollableContainer: boolean = false;

    /**
	 *the scrollable element
	 *
	 * @type {HTMLElement}
	 * @memberof AutoScroll
	 */
    private _scrollableContainer: HTMLElement;

    public set scrollableContainer(container: HTMLElement) {
        this._scrollableContainer = container;
        this._boundaryRect = this.container.getBoundingClientRect();
    }

    public get scrollableContainer() {
        return this._scrollableContainer;
    }

    public set boundaryRect(value: ClientRect) {
        this._boundaryRect = value;
    }

    /**
	 * The position where the mouse is on screen
	 */
    private _point: { x: number; y: number } = { x: 0, y: 0 };

    constructor(private container: HTMLElement, private scrollCallback: () => void) {
        this.scrollableContainer = container;
        this._boundaryRect = this.container.getBoundingClientRect();
    }

    private _scrollTicks: number = 0;

    /**
	 * Handles the move of the scroll action
	 * @param {{ x: number; y: number }} point The point where the mouse is
	 * @memberof AutoScroll
	 */
    public onMove(point: { x: number; y: number }): void {
        this._point = point;
        cancelAnimationFrame(this._animationFrame);
        this._animationFrame = requestAnimationFrame(() => this.scrollTick());
    }


    /**
	 * Do the next scroll action
	 * @private
	 * @memberof AutoScroll
	 */
    private scrollTick(): void {
        cancelAnimationFrame(this._animationFrame);
        if (this.autoScroll()) {
            this._animationFrame = requestAnimationFrame(() => this.scrollTick());
        }
        else {
            this._scrollTicks = 0;
        }
    }

    /**
	 * Checks if scrolling is possible and scrolls the container
	 * @private
	 * @returns {boolean}
	 * @memberof AutoScroll
	 */
    private autoScroll(): boolean {
        let scrolly: number;

        this._scrollTicks = (this._scrollTicks + 1) % 15;

        if (this._point.y < this._boundaryRect.top + this._margin) {
            scrolly = Math.floor(Math.max(-1, (this._point.y - this._boundaryRect.top) / this._margin - 1) * this._maxSpeed);
        } else if (this._point.y > this._boundaryRect.bottom - this._margin) {
            scrolly = Math.ceil(Math.min(1, (this._point.y - this._boundaryRect.bottom) / this._margin + 1) * this._maxSpeed);
        } else {
            scrolly = 0;
        }

        setTimeout(() => {
            if (scrolly) {
                this.scrollY(scrolly);
            }
        });

        return scrolly !== 0;
    }

    /**
	 * Scrolls the Y-axis
	 *
	 * @private
	 * @param {number} amount
	 * @memberof AutoScroll
	 */
    private scrollY(amount: number): void {
        if (this.useWindowAsScrollableContainer)
            window.scrollTo(0, window.scrollY + amount);
        else
            this.container.scrollTop += amount;

        if (this._scrollTicks === 0) {
            this.doCallback();
        }
    }

    private doCallback(): void {
        if (this.scrollCallback) {
            this.scrollCallback();
        }
    }


    public destroy(): void {
        cancelAnimationFrame(this._animationFrame);
    }
}
