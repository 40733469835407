import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NavigationStart, Router } from '@angular/router';
import { AlertDuration } from '@app/_helpers/enums';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AlertService
{
    private subject = new Subject<{ type: 'error' | 'success'; text: string; duration?: AlertDuration }>();
    private keepAfterNavigationChange = false;

    constructor(private router: Router, private snackBar: MatSnackBar, private translate: TranslateService)
    {
        console.log('AlertService created');

        // clear alert message on route change
        router.events.subscribe(event =>
        {
            if (event instanceof NavigationStart)
            {
                if (!this.keepAfterNavigationChange)
                {
                    // clear alert
                    this.subject.next();
                }
            }
        });

        // Log messages to console
        this.getMessage()
            .pipe(filter(message => message != null && message.type != null && message.text != null))
            .subscribe(message =>
            {
                console.log(`Alert ${message.type}: ${message.text}`);
            });

        // Log messages snackbar
        this.getMessage().subscribe(message =>
        {
            if (message && message.text != null)
            {
                this.snackBar.open(message.text, this.translate.instant('CLOSE'), {
                    duration: message.duration.valueOf(),
                    panelClass: message.type === 'error' ? 'warning' : null
                });
            } else
            {
                this.snackBar.dismiss();
            }
        });
    }

    /**
     * Show succes message alert
     * @param message Message to display
     * @param keepAfterNavigationChange Keep alert while navigating
     * @param duration Duration to show the display
     */
    success(message: string, keepAfterNavigationChange = false, duration = AlertDuration.Short)
    {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, duration: duration });
    }

    /**
     * Show error message alert
     * @param message Message to display
     * @param keepAfterNavigationChange Keep alert while navigating
     * @param duration Duration to show the display
     */
    error(message: string, keepAfterNavigationChange = false, duration = AlertDuration.Long)
    {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, duration: duration });
    }

    /**
     * Get message
     */
    getMessage()
    {
        return this.subject;
    }
}
