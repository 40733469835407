import { Component, Host, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs/operators';

@Component({
    templateUrl: './message-page.component.html',
    styleUrls: ['./message-page.component.scss']
})
export class MessagePageComponent implements OnInit
{
    public translation: { title: string; message: string };

    /** gets whether this is not the reloading page. If it is the reloading page,
     * basically, nothing should happen.
     */
    public get notReloading(): boolean
    {
        return this.activatedRoute.snapshot.url.length === 0 ||
            this.activatedRoute.snapshot.url[0].path !== 'reload';
    }

    constructor(
        @Host() protected app: AppComponent,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService
    )
    {
        // Set background
        app.background = true;
    }

    ngOnInit()
    {
        if (this.notReloading)
        {
            const url = this.activatedRoute.snapshot.url;
            const translate = (tag: string) =>
                this.translate.get([`${tag}.TITLE`, `${tag}.MESSAGE`]).pipe(
                    filter(result => Object.keys(result).every(_tag => result[_tag] !== _tag)),
                    map(
                        result =>
                            <{ title: string; message: string }>{
                                title: result[`${tag}.TITLE`],
                                message: result[`${tag}.MESSAGE`]
                            }
                    )
                );

            // Try to get the translation of the first path segment if only 1 exists
            if (url.length === 1)
            {
                translate(url[0].path.toUpperCase()).subscribe(result =>
                {
                    this.translation = result;
                });
            }

            // If no translation set get the default page not found translation
            if (this.translation == null)
            {
                translate('PAGENOTFOUND').subscribe(result =>
                {
                    this.translation = result;
                });
            }
        }
    }
}
