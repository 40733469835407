import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PublicTrainingService = /** @class */ (function () {
    function PublicTrainingService(http) {
        this.http = http;
    }
    PublicTrainingService.prototype.getTraining = function (trainingId) {
        return this.http.get("training/" + trainingId);
    };
    PublicTrainingService.prototype.saveFeedback = function (trainingId, feedback) {
        return this.http.post("training/" + trainingId + "/feedback", feedback);
    };
    PublicTrainingService.prototype.updateFeedback = function (trainingId, feedbackId, feedback) {
        return this.http.put("training/" + trainingId + "/feedback/" + feedbackId, feedback);
    };
    PublicTrainingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicTrainingService_Factory() { return new PublicTrainingService(i0.ɵɵinject(i1.HttpClient)); }, token: PublicTrainingService, providedIn: "root" });
    return PublicTrainingService;
}());
export { PublicTrainingService };
