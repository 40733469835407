import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@env/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

if (environment.ta) {
    // set readiness function
    (<any>window).getReadiness = function () {
        if (document.querySelectorAll('.ng-animating').length > 0) {
            return { IsReady: false, Reason: 'Animations in progress' };
        }

        if (Array.from(document.querySelectorAll('img')).some((image: HTMLImageElement) => !image.complete)) {
            return { IsReady: false, Reason: 'Not all images loaded' };
        }
        return { IsReady: true };
    };

    // append ta script to html
    const script = document.createElement('script');
    script.src = environment.taUrl;
    document.head.appendChild(script);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(module => {
        // Enable debugging if not production environment
        if (!environment.production) {
            const applicationRef = module.injector.get(ApplicationRef);
            const appComponent = applicationRef.components[0];
            enableDebugTools(appComponent);
        }
    });
