import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType } from '@app/_helpers';
import { AlertService, TokenService } from '@app/_services';

@Component({
    templateUrl: './token.component.html'
})
export class TokenComponent
{
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private tokenService: TokenService,
        private alertService: AlertService
    )
    {
        const tokenId = this.route.snapshot.params['id'];

        tokenService.authenticateToken(tokenId).subscribe(() =>
        {
            const user = this.tokenService.user;
            let _route: any[];

            switch (user.aud)
            {
                case UserType.Preview:
                case UserType.Exam:
                    _route = ['learnmaterial', user.lmid];
                    break;

                case UserType.Site:
                case UserType.STE:
                    _route = [''];
                    break;
                case UserType.Waiting:
                    _route = ['waitingroom'];
                    break;
            }

            // Navigate to route
            this.router.navigate(_route, { replaceUrl: true });
        });
    }
}
