var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ErrorHandler, Injector } from '@angular/core';
import { LoggingService } from '@app/_services/logging.service';
import * as StackTrace from 'stacktrace-js';
var GlobalErrorHandler = /** @class */ (function (_super) {
    __extends(GlobalErrorHandler, _super);
    function GlobalErrorHandler(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    GlobalErrorHandler.prototype.handleError = function (error) {
        // If expected error cancel logging
        if (error.error != null && error.error.expected === true)
            return;
        var loggingService = this.injector.get(LoggingService);
        var message = error.message ? error.message : error.toString();
        var request = error.request;
        var localStorage = window.localStorage;
        var sessionStorage = window.sessionStorage;
        var userAgent = window.navigator.userAgent;
        // Get the stack frames and log it
        StackTrace.fromError(error).then(function (stackframes) {
            loggingService.logError(message, { stackframes: stackframes, request: request, localStorage: localStorage, sessionStorage: sessionStorage, userAgent: userAgent });
        }, function () {
            // Log just message if stackframes failed
            loggingService.logError(message, { request: request, localStorage: localStorage, sessionStorage: sessionStorage, userAgent: userAgent });
        });
        // Handle error in angular
        _super.prototype.handleError.call(this, error);
    };
    return GlobalErrorHandler;
}(ErrorHandler));
export { GlobalErrorHandler };
