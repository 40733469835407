import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iLuminus, PublicTraining } from '@app/_models';

@Injectable({
    providedIn: 'root'
})
export class PublicTrainingService {
    constructor(private http: HttpClient) {}

    getTraining(trainingId: string) {
        return this.http.get<PublicTraining>(`training/${trainingId}`);
    }

    saveFeedback(trainingId: string, feedback: iLuminus.Feedback) {
        return this.http.post<string>(`training/${trainingId}/feedback`, feedback);
    }

    updateFeedback(trainingId: string, feedbackId: string, feedback: iLuminus.Feedback) {
        return this.http.put<null>(`training/${trainingId}/feedback/${feedbackId}`, feedback);
    }
}
