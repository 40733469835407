import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenService } from '@app/_services/token.service';

@Injectable({
    providedIn: 'root'
})
export class RegisterService {
    constructor(private http: HttpClient, private token: TokenService) {
        console.log('RegisterService created');
    }

    getRegisterData(learnperiodid: string) {
        return this.http.get<{ organisationname: string }>(`register/registerdata/${learnperiodid}`);
    }
}
