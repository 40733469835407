import { ActivatedRouteSnapshot, DetachedRouteHandle, PreloadingStrategy, Route, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { MessagePageComponent } from '@app/message-page/message-page.component';
import { TokenComponent } from '@app/token/token.component';
import { AuthGuard } from '@app/_guards';
import { UserType } from '@app/_helpers';
import { Observable, of } from 'rxjs';

export class CustomPreloadStrategy implements PreloadingStrategy
{
    preload(route: Route, fn: () => Observable<any>): Observable<any>
    {
        return route.data && route.data.preload ? fn() : of(null);
    }
}

const appRoutes: Routes = [
    { path: 'login', loadChildren: 'app/login/login.module#LoginModule' /** matcher: loginUrlMatcher, */ },
    {
        path: 'learnmaterial/:id',
        loadChildren: 'app/learnmaterial/learnmaterial.module#LearnmaterialModule',
        canActivate: [AuthGuard],
        data: { type: [UserType.Site, UserType.STE, UserType.Exam, UserType.Preview], preload: true }
    },
    { path: 'insight/:id', loadChildren: 'app/portfolio/portfolio.module#PortfolioModule' },
    {
        path: 'twostepauthentication/:userid/:date/:token',
        loadChildren: 'app/twostepauthentication/twostepauthentication.module#TwoStepAuthenticationModule'
    },
    { path: 'supervisor', loadChildren: 'app/supervisor/supervisor.module#SupervisorModule' },
    { path: 'token/:id', component: TokenComponent },
    {
        path: 'register/local/:learnmaterialid/:usergroupid',
        loadChildren: 'app/register/register.module#RegisterModule'
    },
    { path: 'progress/:learnperiodid', loadChildren: 'app/progress/progress.module#ProgressModule' },
    { path: 'training/:trainingid', loadChildren: 'app/public-training/public-training.module#PublicTrainingModule' },
    {
        path: 'waitingroom',
        loadChildren: 'app/waitingroom/waitingroom.module#WaitingroomModule',
        canActivate: [AuthGuard],
        data: { type: [UserType.Waiting] }
    },
    {
        path: '',
        loadChildren: 'app/site/site.module#SiteModule',
        canActivate: [AuthGuard],
        data: { type: [UserType.Site, UserType.STE] }
    },
    { path: '**', component: MessagePageComponent }

    //// otherwise redirect to root
    // { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes, {
    preloadingStrategy: CustomPreloadStrategy,
    onSameUrlNavigation: 'reload'
});

export class CustomRouteReuseStrategy implements RouteReuseStrategy
{
    shouldDetach(route: ActivatedRouteSnapshot): boolean
    {
        return false;
    }
    store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }
    shouldAttach(route: ActivatedRouteSnapshot): boolean
    {
        return false;
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null
    {
        return null;
    }
    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean
    {
        if (future.routeConfig && future.routeConfig.data && future.routeConfig.data.doNotReuse)
        {
            delete future.routeConfig.data.doNotReuse;
            return false;
        }

        return future.routeConfig === curr.routeConfig;
    }
}
