/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./privacy-policy-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./privacy-policy-dialog.component";
import * as i9 from "../../_services/userdata.service";
var styles_PrivacyPolicyDialogComponent = [i0.styles];
var RenderType_PrivacyPolicyDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivacyPolicyDialogComponent, data: {} });
export { RenderType_PrivacyPolicyDialogComponent as RenderType_PrivacyPolicyDialogComponent };
export function View_PrivacyPolicyDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-dialog-content", [["class", "mat-dialog-content"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["color", "accent"], ["mat-dialog-close", ""], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0], [1, "type", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).dialogRef.close(i1.ɵnov(_v, 10).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(10, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(11, 0, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); var currVal_7 = "accent"; _ck(_v, 9, 0, currVal_7); var currVal_8 = ""; _ck(_v, 10, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PRIVACYPOLICY")); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 4, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); var currVal_5 = (i1.ɵnov(_v, 10).ariaLabel || null); var currVal_6 = i1.ɵnov(_v, 10).type; _ck(_v, 8, 0, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("CLOSE")); _ck(_v, 11, 0, currVal_9); }); }
export function View_PrivacyPolicyDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-privacy-policy-dialog", [], null, null, null, View_PrivacyPolicyDialogComponent_0, RenderType_PrivacyPolicyDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.PrivacyPolicyDialogComponent, [i9.UserDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivacyPolicyDialogComponentNgFactory = i1.ɵccf("app-privacy-policy-dialog", i8.PrivacyPolicyDialogComponent, View_PrivacyPolicyDialogComponent_Host_0, {}, {}, []);
export { PrivacyPolicyDialogComponentNgFactory as PrivacyPolicyDialogComponentNgFactory };
