import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/common/http";
var ThemeService = /** @class */ (function () {
    function ThemeService(document, http) {
        var _this = this;
        this.document = document;
        this.http = http;
        this.theme = new BehaviorSubject(null);
        console.log('Theme service created');
        // Subscribe to set theme in document
        this.getTheme()
            .pipe(distinctUntilChanged(), skip(1), // Skip initial value of null
        map(function (theme) { return (theme ? "assets/themes/" + theme + ".css" : null); }))
            .subscribe(function (styleSheetUrl) { return _this.setDocumentStylesheet(styleSheetUrl); });
    }
    ThemeService.prototype.setDocumentStylesheet = function (url) {
        console.log('Theme:' + url);
        var id = 'theme';
        var head = this.document.head;
        var link = head.querySelector("link#" + id);
        if (url) {
            if (link == null) {
                link = this.document.createElement('link');
                link.id = id;
                link.rel = 'stylesheet';
                link.type = 'text/css';
                // Add element after last link stylesheet link
                head.appendChild(link);
            }
            // Set url
            link.href = url;
        }
        else if (link) {
            // Remove link element if no url and link elemen exists
            head.removeChild(link);
        }
    };
    ThemeService.prototype.getTheme = function () {
        return this.theme;
    };
    ThemeService.prototype.setTheme = function (theme) {
        return this.theme.next(theme);
    };
    ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.HttpClient)); }, token: ThemeService, providedIn: "root" });
    return ThemeService;
}());
export { ThemeService };
