import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { environment } from '@env/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
if (environment.ta) {
    // set readiness function
    window.getReadiness = function () {
        if (document.querySelectorAll('.ng-animating').length > 0) {
            return { IsReady: false, Reason: 'Animations in progress' };
        }
        if (Array.from(document.querySelectorAll('img')).some(function (image) { return !image.complete; })) {
            return { IsReady: false, Reason: 'Not all images loaded' };
        }
        return { IsReady: true };
    };
    // append ta script to html
    var script = document.createElement('script');
    script.src = environment.taUrl;
    document.head.appendChild(script);
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .then(function (module) {
    // Enable debugging if not production environment
    if (!environment.production) {
        var applicationRef = module.injector.get(ApplicationRef);
        var appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
    }
});
