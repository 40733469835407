export enum UserType
{
    Site = 'Site',
    Portfolio = 'Portfolio',
    Exam = 'Exam',
    STE = 'STE',
    Supervisor = 'Supervisor',
    Preview = 'Preview',
    Progress = 'Progress',
    Waiting = 'Waiting'
}

export enum WaitingRoomReason
{
    NoContentAvailable = 'NoContentAvailable',
    AccountNotActivated = 'AccountNotActivated'
}

export enum ButtonType
{
    Back = 'Back',
    Menu = 'Menu',
    None = 'None'
}

export enum Examtype
{
    Summative = 0,
    Formative = 1
}

export enum MessageType
{
    UpdateMonitorUser = 0,
    UpdateMonitorLog = 1,
    Logoff = 2,
    UpdatePoints = 3,
    Alert = 4,
    Activated = 5,
    UpdateMonitorUserFilter = 6,
    Ping = 7
}

export enum TicketType
{
    Activation = 0,
    Code = 1
}

export enum LearnMaterialType
{
    Quiz = 0,
    Training = 1
}

export enum CourseStatus
{
    NotStarted = 0,
    Started = 1,
    Finished = 2
}

export enum ErrorHandlingCode
{
    Interceptor = 0,
    UnknownErrorHandler = 1,
    TimeIsUpErrorHandler = 2
}

export enum AlertDuration
{
    Short = 5000,
    Long = 10000
}
