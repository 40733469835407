import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PortfolioResults } from '@app/_models/results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { TokenStoreService } from './tokenstore.service';

@Injectable({
    providedIn: 'root'
})
export class PortfolioService {
    constructor(
        private http: HttpClient,
        private tokenService: TokenService,
        private tokenStoreService: TokenStoreService
    ) {
        console.log('PortfolioService created');
    }

    hasPassword(id: string) {
        return this.http.get<{ hasPassword: boolean }>(`portfolio/${id}/haspassword`);
    }

    setPassword(id: string, password: string) {
        return this.http.post<string>(`portfolio/${id}/setpassword`, password).pipe(
            map(response => {
                // Set new token
                this.tokenService.setToken(response);
                return true;
            })
        );
    }

    authenticate(id: string, password: string) {
        return this.http.post<string>(`portfolio/${id}/authenticate`, password).pipe(
            map(response => {
                // Set new token
                this.tokenService.setToken(response);
                return true;
            })
        );
    }

    getUsers() {
        return this.http.get<{ [key: string]: string }>(`portfolio/users`);
    }

    /**
     * Gets the results of a user (as an observable)
     * @param id The id of the user to get the results of
     */
    getPortfolioResults(id: string): Observable<PortfolioResults> {
        return this.http.get<PortfolioResults>(`portfolio/${id}/results`);
    }

    getAttachment(attachmentid: string) {
        return this.http
            .get(`portfolio/attachment/${attachmentid}`, {
                observe: 'response',
                responseType: 'blob'
            })
            .pipe(
                map(response => {
                    // get the headers' content disposition
                    const cd = response.headers.get('content-disposition');

                    // get the file name with regex
                    const regex = /filename[^;=\n]*=((['"])(.*?)\2|[^;\n]*)/;
                    const fileName = regex.exec(cd)[3];

                    return {
                        blob: response.body,
                        filename: fileName
                    };
                })
            );
    }

    requestResetPasswordLink(id: string) {
        return this.http.get<string>(`portfolio/${id}/requestresetpasswordlink`);
    }

    passwordResetTokenExpired(passwordResetToken: string) {
        return this.http.get<boolean>(`portfolio/resetpasswordtokenexpired/${passwordResetToken}`);
    }

    resetPassword(passwordResetToken: string, password: string) {
        return this.http.post<string>(`portfolio/resetpassword/${passwordResetToken}`, password).pipe(
            map(response => {
                this.tokenService.setToken(response);
            })
        );
    }
}
