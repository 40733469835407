import { Injectable } from '@angular/core';
import { User } from '@app/_models';
import { JwtHelperService } from '@auth0/angular-jwt';

// Set constants
const TOKEN_KEY = 'token';
const JWT_HELPER = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class TokenStoreService
{
    private _token: string;
    private _user: User;
    private _storage = localStorage;

    get token(): string
    {
        return this._token;
    }

    get user(): User
    {
        return this.authenticated ? this._user : null;
    }

    constructor()
    {
        console.log('TokenStoreService created');

        // Try to load the token from the store
        this._token = this._storage.getItem(TOKEN_KEY);

        // Save the current token to the store before the window is unloaded
        window.addEventListener('beforeunload', this.saveTokenToStorage.bind(this));
    }

    /**
     * Gets the originating url from the JW token
     */
    public getOriginatingUrl()
    {
        if (this._token)
        {
            return JWT_HELPER.decodeToken(this._token).ourl;
        }

        return null;
    }

    public setToken(token: string | null, saveToStorage = true)
    {

        // Set the token
        this._token = token;

        // Set the user
        if (token == null)
        {
            this._user = null;
            (<any>window)['currentUserID'] = null;
        } else
        {
            this._user = JWT_HELPER.decodeToken(token);
            (<any>window)['currentUserID'] = this._user.nameid;
        }

        // Save the token
        if (saveToStorage)
        {
            this.saveTokenToStorage();
        }
    }

    private saveTokenToStorage()
    {
        if (this._token == null)
        {
            this._storage.removeItem(TOKEN_KEY);
        } else
        {
            this._storage.setItem(TOKEN_KEY, this._token);
        }
    }

    get authenticated()
    {
        return !JWT_HELPER.isTokenExpired(this._token);
    }
}
