<h1 mat-dialog-title>
    <div class="connectionalert"><mat-icon>error_outline</mat-icon></div>
    {{ 'CONNECTION.ISSUES' | translate }}
</h1>
<mat-dialog-content>
    <p class="connectionalertparagraph">
        <span class="connectionalert"><mat-icon>error_outline</mat-icon></span
        >{{ 'CONNECTION.EXPLAIN1' | translate }}
    </p>
    <p class="connectionalertparagraph">
        <span class="connectionalert"><mat-icon>error_outline</mat-icon></span
        >{{ 'CONNECTION.EXPLAIN2' | translate }}
    </p>
    <p class="connectionalertparagraph">
        <span class="connectionalert"><mat-icon>error_outline</mat-icon></span
        >{{ 'CONNECTION.EXPLAIN3' | translate }}
    </p>
    <p class="connectionalertparagraph">
        <span class="connectionalert"><mat-icon>error_outline</mat-icon></span
        >{{ 'CONNECTION.EXPLAIN4' | translate }}
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="accent">{{ 'CLOSE' | translate }}</button>
</mat-dialog-actions>
