import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { SupervisorService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class CustomMatPaginator extends MatPaginatorIntl implements OnDestroy
{
    // subscription for updating the selected user count label with translations
    private _labelSubscription$: Subscription;

    constructor(private translate: TranslateService,
        private supervisorService: SupervisorService)
    {
        super();

        this._labelSubscription$ = combineLatest([
            (<Observable<string>>this.translate.get('PAGINATION.ITEMS_PER_PAGE')).pipe(take(1)),
            (<Observable<string>>this.translate.get('PAGINATION.SELECTED_USER_COUNT')).pipe(take(1)),
            this.supervisorService.selectionCount()]).pipe(map(([transItemsPerPage, transSelectUserCount, selectUserCount]) =>
            {
                return {
                    'transItemsPerPage': transItemsPerPage,
                    'transSelectUserCount': transSelectUserCount,
                    'selectUserCount': selectUserCount
                };
            })).subscribe((value: {
                'transItemsPerPage': string,
                'transSelectUserCount': string,
                'selectUserCount': number
            }) =>
            {
                this.itemsPerPageLabel = value.transSelectUserCount + ': ' + value.selectUserCount + Array(10).fill('\xa0').join('') + value.transItemsPerPage + ':';

                // force paginator component to update
                this.changes.next();
            });

    }

    ngOnDestroy(): void
    {
        this._labelSubscription$.unsubscribe();
    }

    // Did not implement nextPageLabel, previousPageLabel and changes on purpose
    // Labels are not used and the default changes
    getRangeLabel = function (page: number, pageSize: number, length: number)
    {
        if (length === 0 || pageSize === 0)
        {
            return this.translate.instant('PAGINATION.PAGE_POSITION', {
                startIndex: 0,
                endIndex: 0,
                length: length
            });
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

        return this.translate.instant('PAGINATION.PAGE_POSITION', {
            startIndex: startIndex + 1,
            endIndex: endIndex,
            length: length
        });
    };
}
