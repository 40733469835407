import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./tokenstore.service";
import * as i3 from "./config.service";
var LoggingService = /** @class */ (function () {
    function LoggingService(http, tokenStoreService, configService) {
        this.http = http;
        this.tokenStoreService = tokenStoreService;
        this.configService = configService;
    }
    LoggingService.prototype.logError = function (message, data) {
        var user = this.tokenStoreService.user;
        this.http
            .post('log/error', {
            message: message,
            user_id: user ? user.nameid : null,
            url: window.location.href,
            data: data,
            user_agent: navigator.userAgent
        })
            .subscribe();
    };
    LoggingService.prototype.logUsage = function (usage, data) {
        var user = this.tokenStoreService.user;
        this.http
            .post('log/usage', {
            usage: usage,
            user: user ? user.nameid : null,
            url: window.location.href,
            data: data
        })
            .subscribe();
    };
    LoggingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggingService_Factory() { return new LoggingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenStoreService), i0.ɵɵinject(i3.ConfigService)); }, token: LoggingService, providedIn: "root" });
    return LoggingService;
}());
export { LoggingService };
