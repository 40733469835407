import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var ScriptLoaderService = /** @class */ (function () {
    function ScriptLoaderService(_document) {
        this._document = _document;
    }
    ScriptLoaderService.prototype.loadScript = function (id, url) {
        var _this = this;
        // Check arguments
        if (!id || !url) {
            throw new Error("id and url arguments cannot be empty");
        }
        return new Observable(function (observer) {
            if (_this._document.querySelector("script[id=\"" + id + "\"][src=\"" + url + "\"]")) {
                // Complete if script already loaded
                observer.next(url);
                observer.complete();
            }
            else {
                // Load the script
                var script = _this._document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = function () {
                    observer.next(url);
                    observer.complete();
                };
                script.onerror = function (error) {
                    observer.error("Couldn't load script " + url);
                };
                _this._document.body.appendChild(script);
            }
        });
    };
    ScriptLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ScriptLoaderService_Factory() { return new ScriptLoaderService(i0.ɵɵinject(i1.DOCUMENT)); }, token: ScriptLoaderService, providedIn: "root" });
    return ScriptLoaderService;
}());
export { ScriptLoaderService };
