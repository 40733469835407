import { ElementRef } from '@angular/core';
var ATTRIBUTE_NAMES = ['ta', 'data-ta'];
var TestAutomationDirective = /** @class */ (function () {
    function TestAutomationDirective(el) {
        this.el = el;
    }
    Object.defineProperty(TestAutomationDirective.prototype, "ta", {
        set: function (value) {
            var _this = this;
            // Set the attribute
            if (typeof value === 'string') {
                ATTRIBUTE_NAMES.forEach(function (ATTRIBUTE_NAME) { return _this.el.nativeElement.setAttribute(ATTRIBUTE_NAME, value); });
            }
            else {
                var _loop_1 = function (key) {
                    if (value.hasOwnProperty(key)) {
                        ATTRIBUTE_NAMES.forEach(function (ATTRIBUTE_NAME) {
                            return _this.el.nativeElement.setAttribute(ATTRIBUTE_NAME + "-" + key, value[key]);
                        });
                    }
                };
                for (var key in value) {
                    _loop_1(key);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return TestAutomationDirective;
}());
export { TestAutomationDirective };
