import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Results, ScoreBySubject } from '@app/_models/results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenStoreService } from './tokenstore.service';

interface PortfolioUser {
    hasPermission: boolean;
    portfolios: { id: string; email: string }[];
}

interface Settings {
    username: string;
    initials: string;
    lastname: string;
    firstname: string;
    email: string;
    insertion: string;
    allowChangePassword: boolean;
    allowChangeUserDetails: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserDataService {
    constructor(private http: HttpClient, private tokenStoreService: TokenStoreService) {
        console.log('UserDataService created');
    }

    getMyCoursesData() {
        return this.http.get<{ locations: any; courses: any; statistics: any; subscriptions: any }>(
            'userdata/mycoursesdata'
        );
    }

    /**
     * Gets the results for a user. This includes results the user added themselves.
     */
    public getResults(): Observable<{ results: Results; canAddResults: boolean }> {
        return this.http.get<{ results: Results; canAddResults: boolean }>('userdata/results');
    }

    public getSubjectScores(id: string, attempt: number): Observable<ScoreBySubject[]> {
        return this.http.get<ScoreBySubject[]>(`userdata/subjectscores/${id}/${attempt}`);
    }

    public addResult(result: any) {
        return this.http.post<{ succeeded: boolean }>('userdata/results/add', result);
    }

    /**
     * Updates a result with a new title, description data and added and removed attachments
     * @param result The new result data
     */
    public updateResult(result: {
        id: string;
        title: string;
        date: Date;
        description: string;
        files: { fileID: string; fileName: string }[];
        removedAttachmentIds: string[];
    }): Observable<{ succeeded: boolean }> {
        return this.http.put<{ succeeded: boolean }>('userdata/results/update', result);
    }

    public addAttachment(formData: FormData): Observable<HttpEvent<{ tmpFileID: string; fileName: string }>> {
        return this.http.post<{ tmpFileID: string; fileName: string }>('userdata/results/add/certificate', formData, {
            reportProgress: true,
            observe: 'events',
        });
    }

    public getAttachment(attachmentID: string) {
        return this.http
            .get(`userdata/results/attachment/${attachmentID}`, { observe: 'response', responseType: 'blob' })
            .pipe(
                map((response) => {
                    // get the headers' content disposition
                    const cd = response.headers.get('content-disposition');

                    // get the file name with regex
                    const regex = /filename=['"]?([^'";]+)['"]?/;
                    const fileName = regex.exec(cd)[1];

                    return {
                        blob: response.body,
                        filename: fileName,
                    };
                })
            );
    }

    /**
     * Deletes one attachment
     * @param attachmentName The name of the attachment to delete
     */
    public deleteAttachment(attachmentName: string) {
        return this.http.post<{ deleted: boolean }>('userdata/results/delete/certificate', attachmentName);
    }

    public deleteResult(id: string) {
        return this.http.post<{ deleted: boolean }>('userdata/results/delete', id);
    }

    public getSettings() {
        return this.http.get<Settings>('userdata/settings');
    }

    public setSettings(settings: any) {
        return this.http.post('userdata/settings/update', settings);
    }

    /**
     * Attempts to set a new password
     * @param newPassword The new password to set
     * @param currentPassword The current password, for verification
     */
    public setPassword(data: { newPassword: string; currentPassword: string }) {
        return this.http.post('userdata/settings/update/password', data);
    }

    public getPortfolios() {
        return this.http.get<PortfolioUser>('userdata/portfolio');
    }

    public addPortfolio(data: { email: string; language: string }) {
        return this.http.post<{ id: string; email: string }>('userdata/portfolio/add', data);
    }

    public deletePortfolio(id: string) {
        return this.http.post<{ deleted: boolean }>('userdata/portfolio/delete', id);
    }

    public getSubscriptions(subscriptionpageid: string) {
        return this.http.get<{ id: string; title: string }>(`userdata/subscription/${subscriptionpageid}`);
    }

    public subscribe(subscriptionpageid: string) {
        return this.http.post('userdata/subscribe', subscriptionpageid);
    }

    public requestResetPasswordLink(data: { usernameOrEmail: string; language: string }) {
        return this.http.post<{ email: string }>('userdata/requestresetpasswordlink', data);
    }

    public canResetPassword(userid: string, resetpasswordid: string) {
        return this.http.get<boolean>(`userdata/canresetpassword/${userid}/${resetpasswordid}`);
    }

    public resetPassword(userid: string, resetpasswordid: string, password: string) {
        return this.http.post(`userdata/resetpassword/${userid}/${resetpasswordid}`, password);
    }

    public getPrivacyPolicy() {
        return this.http.get<string>(`userdata/privacypolicy`);
    }

    public setUserLanguage(data: string) {
        return this.http.post('userdata/language/update', data);
    }
}
