import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TwoStepAuthenticationService {
    constructor(private http: HttpClient) {
        console.log('TwoStepAuthenticationService created');
    }

    loadTwoStepAuthenticationData(data: { userid: string; date: string; token: string }) {
        return this.http.get(`authentication/${data.userid}/${data.date}/${data.token}`);
    }
}
