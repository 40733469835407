/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./token.component";
import * as i2 from "@angular/router";
import * as i3 from "../_services/token.service";
import * as i4 from "../_services/alert.service";
var styles_TokenComponent = [];
var RenderType_TokenComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TokenComponent, data: {} });
export { RenderType_TokenComponent as RenderType_TokenComponent };
export function View_TokenComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_TokenComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_TokenComponent_0, RenderType_TokenComponent)), i0.ɵdid(1, 49152, null, 0, i1.TokenComponent, [i2.Router, i2.ActivatedRoute, i3.TokenService, i4.AlertService], null, null)], null, null); }
var TokenComponentNgFactory = i0.ɵccf("ng-component", i1.TokenComponent, View_TokenComponent_Host_0, {}, {}, []);
export { TokenComponentNgFactory as TokenComponentNgFactory };
