import { registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import localeNL from '@angular/common/locales/nl';
import { Injectable, Injector } from '@angular/core';
import { IConfigService } from '@app/_interfaces/iconfigservice';
import { Config, ConfigBase } from '@app/_models';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from './theme.service';
import { TokenStoreService } from './tokenstore.service';

@Injectable({
    providedIn: 'root'
})
export class ConfigService implements IConfigService {
    /** Copy of the current configuration */
    private _config: Config;

    constructor(
        private injector: Injector,
        private translate: TranslateService,
        private themeService: ThemeService,
        private tokenStoreService: TokenStoreService,
        private http: HttpClient
    ) {
        console.log('Config service created');

        // register locale for pipe directives that uses i18n
        // Datepipe, CurrencyPipe, DecimalPipe, PercentPipe
        registerLocaleData(localeNL, 'nl-NL');
        registerLocaleData(localeDE, 'de-DE');
        registerLocaleData(localeFR, 'fr-FR');
    }

    load(): Promise<any> {
        return new Promise((resolve, reject) => {
            // Setup translate
            this.translate.addLangs(['en-US', 'nl-NL', 'de-DE', 'fr-FR']);
            this.translate.setDefaultLang('en-US');

            this.http.post<Config>('config', { url: window.location.hostname }).subscribe(config => {
                // Store the config

                this._config = config;

                // Apply the config on load
                this.update();

                // Resolve the promise
                resolve();
            });
        });
    }

    /** Gets the current configuration */
    get config(): Config {
        return this._config;
    }

    update(config: ConfigBase = null): void {

        const user = this.tokenStoreService.user;

        const theme = (config && config.theme) || (user ? user.thm || null : this.config.theme || null);
        const language = (config && config.language) || (user && user.lng) || this.config.language;

        // update config language from local confic object, needed to set the language
        this._config.language = language;

        // Set theme
        this.themeService.setTheme(theme);

        // Set language
        if (language != null && this.translate.getLangs().map(l => l.toLowerCase()).includes(language.toLowerCase())) {
            this.translate.use(language);
        }
    }
}
