import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecureLocationConfig } from '@app/_models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class SupervisorService
{
    private _selectionCount$: BehaviorSubject<Number>;

    public selectionCount(): BehaviorSubject<Number>
    {
        return this._selectionCount$;
    }

    constructor(private http: HttpClient, private tokenService: TokenService)
    {
        console.log('SupervisorService created');
        this._selectionCount$ = new BehaviorSubject(0);
    }

    /**
     * Update the selection count
     * @param value new
     */
    public updateSelectionCount(value: Number): void
    {
        this._selectionCount$.next(value);
    }

    public getSecureLocations(): Observable<SecureLocationConfig[]>
    {
        return this.http.get<SecureLocationConfig[]>('supervisor/securelocations');
    }

    public setSecureLocation(locationId: string)
    {
        return this.http.post<string>('supervisor/securelocation', locationId).pipe(
            map(token =>
            {
                this.tokenService.setToken(token);
            })
        );
    }

    public activateUsers(userids: string[])
    {
        return this.http.post('supervisor/activateUsers', userids);
    }

    public activateTicketcode(ticketcode: string)
    {
        return this.http.post('supervisor/activateticketcode', ticketcode);
    }

    public closeExams(userids: string[])
    {
        return this.http.post('supervisor/closeExams', userids);
    }

    public deactivateUsers(userids: string[])
    {
        return this.http.post('supervisor/deactivateUsers', userids);
    }

    public getUsers(): Observable<Object>
    {
        return this.http.get('supervisor/users');
    }

    /**
     * Get the learnperiods for the securelocation
     * @param secureLocationId secure location id
     */
    public getFilters(secureLocationId: string): Observable<Object>
    {
        return this.http.get(`supervisor/filterdata/${secureLocationId}`);
    }
}
