export abstract class Window {
    static getCenterOfScreen(windowWidth: number, windowHeight: number): WindowPosition {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : (<any>screen).left;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : (<any>screen).top;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;

        const left = width / 2 - windowWidth / 2 + dualScreenLeft;
        const top = height / 2 - windowHeight / 2 + dualScreenTop;

        const windowPosition: WindowPosition = { top: top, left: left };
        return windowPosition;
    }
}

export class WindowPosition {
    public top: number;
    public left: number;
}
