import { HttpClient } from '@angular/common/http';
import { TokenService } from '@app/_services/token.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token.service";
var RegisterService = /** @class */ (function () {
    function RegisterService(http, token) {
        this.http = http;
        this.token = token;
        console.log('RegisterService created');
    }
    RegisterService.prototype.getRegisterData = function (learnperiodid) {
        return this.http.get("register/registerdata/" + learnperiodid);
    };
    RegisterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegisterService_Factory() { return new RegisterService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenService)); }, token: RegisterService, providedIn: "root" });
    return RegisterService;
}());
export { RegisterService };
