import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ErrorHandlingCode } from '@app/_helpers';
import { iLuminus, Learnmaterial } from '@app/_models';
import { environment } from '@env/environment';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { delay, finalize, mergeMap, retryWhen } from 'rxjs/operators';
import { TokenStoreService } from './tokenstore.service';

export interface UserData
{
    confirmed: boolean;
    time: number;
    userAnswers: any;
}

@Injectable({
    providedIn: 'root'
})
export class LearnmaterialService
{
    private _outstandingImportantRequests: number = 0;

    public UnknownErrorOccurred$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public TimeIsUpErrorOccurred$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    /** Gets the current value of the 'not found error occurred' indicator */
    public get timeIsUpErrorOccurred(): boolean
    {
        return this.TimeIsUpErrorOccurred$.value;
    }

    /** Sets the current value of the 'not found error occurred' indicator */
    public set timeIsUpErrorOccurred(value: boolean)
    {
        this.TimeIsUpErrorOccurred$.next(value);
    }

    constructor(private http: HttpClient, private tokenStoreService: TokenStoreService, private _zone: NgZone)
    {
        console.log('LearnmaterialService created');
    }

    getLearnmaterial(id: string)
    {
        return this.http.get<Learnmaterial>(`learnmaterial/${id}`);
    }

    retakeExam(learnmaterialId: string)
    {
        return this.http.post(`learnmaterial/${learnmaterialId}/retake`, null);
    }

    closeExam(learnmaterialId: string, userData: { [id: string]: any })
    {
        this._outstandingImportantRequests++;

        return this.http.post(`learnmaterial/${learnmaterialId}/close`,
            userData
        ).pipe(
            this.delayedRetry(),
            finalize(() =>
            {
                this._outstandingImportantRequests--;
                if (this._outstandingImportantRequests < 0)
                {
                    console.log('Outstanding requests < 0?');
                    this._outstandingImportantRequests = 0;
                }
                if (this._outstandingImportantRequests === 0)
                    this.UnknownErrorOccurred$.next(false);
            }));
    }

    updateQuestion(learnmaterialId: string, questionId: string, userData: any)
    {
        this._outstandingImportantRequests++;

        return this.http.post<{ question?: any; results?: any; status?: number; pages?: any[] }>(
            `learnmaterial/${learnmaterialId}/update/${questionId}`,
            userData
        ).pipe(
            this.delayedRetry(),
            finalize(() =>
            {
                this._outstandingImportantRequests--;
                if (this._outstandingImportantRequests < 0)
                {
                    console.log('Outstanding requests < 0?');
                    this._outstandingImportantRequests = 0;
                }
                if (this._outstandingImportantRequests === 0)
                    this.UnknownErrorOccurred$.next(false);
            }));
    }

    updateQuestionSync(learnmaterialId: string, questionId: string, userData: any)
    {

        const url = `${environment.apiUrl}learnmaterial/${learnmaterialId}/update/${questionId}`;

        if (typeof fetch === undefined)
        {
            // Do an synchronous request
            const client = new XMLHttpRequest();
            client.open('POST', url, false);
            client.setRequestHeader('Content-Type', 'application/json');
            client.setRequestHeader('Authorization', `Bearer ${this.tokenStoreService.token}`);
            client.send(JSON.stringify(userData));
        }
        else
        {
            fetch(url, {
                method: 'POST',
                headers:
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.tokenStoreService.token}`
                },
                body: JSON.stringify(userData),
                keepalive: true // ensure that the request continues whether or not the page closes
            });
        }
    }

    retryPointQueueJob(learnmaterialID: string, certificateID: string, relationNumber: string)
    {
        return this.http.post(`learnmaterial/${learnmaterialID}/points/retry/${certificateID}`, {
            relationNumber: relationNumber
        });
    }

    sendFeedback(learnmaterialId: string, feedbackData: any)
    {
        return this.http.post(`learnmaterial/${learnmaterialId}/feedback`, feedbackData);
    }

    updateProgress(learnmaterialId: string, progress: iLuminus.Progress)
    {
        return this.http.post(`learnmaterial/${learnmaterialId}/progress`, progress);
    }

    updateFeedback(learnmaterialId: string, feedback: iLuminus.Feedback)
    {
        return this.http.post(`learnmaterial/${learnmaterialId}/training/feedback`, feedback);
    }

    /** Only for training types that open in popup */
    openTraining(learnmaterial: any)
    {
        // Make url
        const url = `${location.protocol}//${window.location.hostname}/api/learnmaterial/${learnmaterial.id}/training`;
        const resolution = learnmaterial.popup.resolution.match(/\d+/g);

        const width = resolution[0];
        const height = resolution[1];

        const center = this.getCenterOfScreen(width, height);

        // try create popup, if browser cannot open popup open training in current window
        try
        {
            // create window
            const windowRef = window.open(
                `/assets/blank.html`,
                '',
                `width=${width},height=${height},top=${center.top}, left=${center.left}`
            );
            // IE workaround, added property on blank html to check if window is loaded. IE fires onload event to soon
            if ((<any>windowRef.window)['windowLoaded'])
            {
                this.loadTraining(windowRef, url);
            } else
            {
                windowRef.window.addEventListener('load', () => this.loadTraining(windowRef, url));
            }
        } catch (e)
        {
            // window could not be opened, load training in current
            this.loadTraining(window, url);
        }
    }

    private loadTraining(windowRef: Window, url: string)
    {
        let form = null;
        try
        {
            // create form
            form = this.createTempForm(windowRef, url);
        } catch (e)
        {
            // check if the window was opened and close it
            if (windowRef.opener && window.opener !== window)
            {
                windowRef.close();
            }

            // open training in current window
            windowRef = window;
            form = this.createTempForm(windowRef, url);
        }

        const hidToken = <HTMLInputElement>windowRef.document.createElement('input');
        hidToken.name = 'token';
        hidToken.setAttribute('value', this.tokenStoreService.token);
        hidToken.type = 'hidden';
        form.appendChild(hidToken);

        windowRef.document.body.appendChild(form);

        form.submit();
    }

    private createTempForm(windowRef: Window, url: string)
    {
        const form = <HTMLFormElement>windowRef.document.createElement('form');
        form.id = 'training_form';
        form.action = url;
        form.method = 'post';

        return form;
    }

    public getAttachmentIDFromCertificate(learnmaterialid: string, certificateid: string)
    {
        return this.http.get<string>(`learnmaterial/${learnmaterialid}/attachment/${certificateid}`);
    }

    private getCenterOfScreen(windowWidth: number, windowHeight: number)
    {
        // Fixes dual-screen position                         Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : (<any>screen).left;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : (<any>screen).top;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;

        const left = width / 2 - windowWidth / 2 + dualScreenLeft;
        const top = height / 2 - windowHeight / 2 + dualScreenTop;

        return { top: top, left: left };
    }

    private delayedRetry()
    {
        let retryDelay: number = -2800;
        return (src: Observable<any>) =>
        {
            return src.pipe(
                retryWhen((errors: Observable<any>): Observable<any> =>
                {
                    return errors.pipe(
                        mergeMap((error) =>
                        {
                            if (error.handledBy === ErrorHandlingCode.UnknownErrorHandler)
                            {
                                this.UnknownErrorOccurred$.next(true);
                                if (retryDelay < 3000)
                                    retryDelay += 2900;
                                return of(error).pipe(delay(retryDelay));
                            }
                            else if (error.handledBy === ErrorHandlingCode.TimeIsUpErrorHandler)
                            {
                                this.TimeIsUpErrorOccurred$.next(true);
                                return EMPTY;
                            }

                            return throwError(error);
                        }));
                }));
        };
    }
}
