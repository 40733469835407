import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { TokenStoreService } from './tokenstore.service';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(
        private http: HttpClient,
        private tokenStoreService: TokenStoreService,
        private configService: ConfigService
    ) {}

    logError(message: string, data: any) {
        const user = this.tokenStoreService.user;

        this.http
            .post('log/error', {
                message,
                user_id: user ? user.nameid : null,
                url: window.location.href,
                data,
                user_agent: navigator.userAgent
            })
            .subscribe();
    }

    logUsage(usage: string, data: any) {
        const user = this.tokenStoreService.user;

        this.http
            .post('log/usage', {
                usage,
                user: user ? user.nameid : null,
                url: window.location.href,
                data
            })
            .subscribe();
    }
}
