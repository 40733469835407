import { HttpClient } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { ErrorHandlingCode } from '@app/_helpers';
import { environment } from '@env/environment';
import { BehaviorSubject, EMPTY, of, throwError } from 'rxjs';
import { delay, finalize, mergeMap, retryWhen } from 'rxjs/operators';
import { TokenStoreService } from './tokenstore.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./tokenstore.service";
var LearnmaterialService = /** @class */ (function () {
    function LearnmaterialService(http, tokenStoreService, _zone) {
        this.http = http;
        this.tokenStoreService = tokenStoreService;
        this._zone = _zone;
        this._outstandingImportantRequests = 0;
        this.UnknownErrorOccurred$ = new BehaviorSubject(false);
        this.TimeIsUpErrorOccurred$ = new BehaviorSubject(false);
        console.log('LearnmaterialService created');
    }
    Object.defineProperty(LearnmaterialService.prototype, "timeIsUpErrorOccurred", {
        /** Gets the current value of the 'not found error occurred' indicator */
        get: function () {
            return this.TimeIsUpErrorOccurred$.value;
        },
        /** Sets the current value of the 'not found error occurred' indicator */
        set: function (value) {
            this.TimeIsUpErrorOccurred$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    LearnmaterialService.prototype.getLearnmaterial = function (id) {
        return this.http.get("learnmaterial/" + id);
    };
    LearnmaterialService.prototype.retakeExam = function (learnmaterialId) {
        return this.http.post("learnmaterial/" + learnmaterialId + "/retake", null);
    };
    LearnmaterialService.prototype.closeExam = function (learnmaterialId, userData) {
        var _this = this;
        this._outstandingImportantRequests++;
        return this.http.post("learnmaterial/" + learnmaterialId + "/close", userData).pipe(this.delayedRetry(), finalize(function () {
            _this._outstandingImportantRequests--;
            if (_this._outstandingImportantRequests < 0) {
                console.log('Outstanding requests < 0?');
                _this._outstandingImportantRequests = 0;
            }
            if (_this._outstandingImportantRequests === 0)
                _this.UnknownErrorOccurred$.next(false);
        }));
    };
    LearnmaterialService.prototype.updateQuestion = function (learnmaterialId, questionId, userData) {
        var _this = this;
        this._outstandingImportantRequests++;
        return this.http.post("learnmaterial/" + learnmaterialId + "/update/" + questionId, userData).pipe(this.delayedRetry(), finalize(function () {
            _this._outstandingImportantRequests--;
            if (_this._outstandingImportantRequests < 0) {
                console.log('Outstanding requests < 0?');
                _this._outstandingImportantRequests = 0;
            }
            if (_this._outstandingImportantRequests === 0)
                _this.UnknownErrorOccurred$.next(false);
        }));
    };
    LearnmaterialService.prototype.updateQuestionSync = function (learnmaterialId, questionId, userData) {
        var url = environment.apiUrl + "learnmaterial/" + learnmaterialId + "/update/" + questionId;
        if (typeof fetch === undefined) {
            // Do an synchronous request
            var client = new XMLHttpRequest();
            client.open('POST', url, false);
            client.setRequestHeader('Content-Type', 'application/json');
            client.setRequestHeader('Authorization', "Bearer " + this.tokenStoreService.token);
            client.send(JSON.stringify(userData));
        }
        else {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + this.tokenStoreService.token
                },
                body: JSON.stringify(userData),
                keepalive: true // ensure that the request continues whether or not the page closes
            });
        }
    };
    LearnmaterialService.prototype.retryPointQueueJob = function (learnmaterialID, certificateID, relationNumber) {
        return this.http.post("learnmaterial/" + learnmaterialID + "/points/retry/" + certificateID, {
            relationNumber: relationNumber
        });
    };
    LearnmaterialService.prototype.sendFeedback = function (learnmaterialId, feedbackData) {
        return this.http.post("learnmaterial/" + learnmaterialId + "/feedback", feedbackData);
    };
    LearnmaterialService.prototype.updateProgress = function (learnmaterialId, progress) {
        return this.http.post("learnmaterial/" + learnmaterialId + "/progress", progress);
    };
    LearnmaterialService.prototype.updateFeedback = function (learnmaterialId, feedback) {
        return this.http.post("learnmaterial/" + learnmaterialId + "/training/feedback", feedback);
    };
    /** Only for training types that open in popup */
    LearnmaterialService.prototype.openTraining = function (learnmaterial) {
        var _this = this;
        // Make url
        var url = location.protocol + "//" + window.location.hostname + "/api/learnmaterial/" + learnmaterial.id + "/training";
        var resolution = learnmaterial.popup.resolution.match(/\d+/g);
        var width = resolution[0];
        var height = resolution[1];
        var center = this.getCenterOfScreen(width, height);
        // try create popup, if browser cannot open popup open training in current window
        try {
            // create window
            var windowRef_1 = window.open("/assets/blank.html", '', "width=" + width + ",height=" + height + ",top=" + center.top + ", left=" + center.left);
            // IE workaround, added property on blank html to check if window is loaded. IE fires onload event to soon
            if (windowRef_1.window['windowLoaded']) {
                this.loadTraining(windowRef_1, url);
            }
            else {
                windowRef_1.window.addEventListener('load', function () { return _this.loadTraining(windowRef_1, url); });
            }
        }
        catch (e) {
            // window could not be opened, load training in current
            this.loadTraining(window, url);
        }
    };
    LearnmaterialService.prototype.loadTraining = function (windowRef, url) {
        var form = null;
        try {
            // create form
            form = this.createTempForm(windowRef, url);
        }
        catch (e) {
            // check if the window was opened and close it
            if (windowRef.opener && window.opener !== window) {
                windowRef.close();
            }
            // open training in current window
            windowRef = window;
            form = this.createTempForm(windowRef, url);
        }
        var hidToken = windowRef.document.createElement('input');
        hidToken.name = 'token';
        hidToken.setAttribute('value', this.tokenStoreService.token);
        hidToken.type = 'hidden';
        form.appendChild(hidToken);
        windowRef.document.body.appendChild(form);
        form.submit();
    };
    LearnmaterialService.prototype.createTempForm = function (windowRef, url) {
        var form = windowRef.document.createElement('form');
        form.id = 'training_form';
        form.action = url;
        form.method = 'post';
        return form;
    };
    LearnmaterialService.prototype.getAttachmentIDFromCertificate = function (learnmaterialid, certificateid) {
        return this.http.get("learnmaterial/" + learnmaterialid + "/attachment/" + certificateid);
    };
    LearnmaterialService.prototype.getCenterOfScreen = function (windowWidth, windowHeight) {
        // Fixes dual-screen position                         Most browsers      Firefox
        var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
        var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
        var width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
        var height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;
        var left = width / 2 - windowWidth / 2 + dualScreenLeft;
        var top = height / 2 - windowHeight / 2 + dualScreenTop;
        return { top: top, left: left };
    };
    LearnmaterialService.prototype.delayedRetry = function () {
        var _this = this;
        var retryDelay = -2800;
        return function (src) {
            return src.pipe(retryWhen(function (errors) {
                return errors.pipe(mergeMap(function (error) {
                    if (error.handledBy === ErrorHandlingCode.UnknownErrorHandler) {
                        _this.UnknownErrorOccurred$.next(true);
                        if (retryDelay < 3000)
                            retryDelay += 2900;
                        return of(error).pipe(delay(retryDelay));
                    }
                    else if (error.handledBy === ErrorHandlingCode.TimeIsUpErrorHandler) {
                        _this.TimeIsUpErrorOccurred$.next(true);
                        return EMPTY;
                    }
                    return throwError(error);
                }));
            }));
        };
    };
    LearnmaterialService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LearnmaterialService_Factory() { return new LearnmaterialService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenStoreService), i0.ɵɵinject(i0.NgZone)); }, token: LearnmaterialService, providedIn: "root" });
    return LearnmaterialService;
}());
export { LearnmaterialService };
