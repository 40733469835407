import { Language } from '@app/_models/language';

export const SECURELOCATIONID_SESSIONKEY = 'selectedSecureLocationId';

export const USER_LANGUAGE_KEY = 'iqualify_language_key';

export const LANGUAGES: Language[] = [
    { name: 'Nederlands', code: 'nl-NL' },
    { name: 'Français', code: 'fr-FR' },
    { name: 'Deutsch', code: 'de-DE' },
    { name: 'English', code: 'en-US' }];

