import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpClientActiveRequestsService {
    private _activeRequests: HttpRequest<any>[] = [];
    private _activeRequests$ = new BehaviorSubject<HttpRequest<any>[]>([]);

    constructor() {
        console.log('HttpClientActiveRequests service created');
    }

    add(req: HttpRequest<any>) {
        this._activeRequests.push(req);
        this._activeRequests$.next(this._activeRequests);
    }

    remove(req: HttpRequest<any>) {
        const index = this._activeRequests.indexOf(req);

        if (index > -1) {
            this._activeRequests.splice(index, 1);
        }

        this._activeRequests$.next(this._activeRequests);
    }

    requests() {
        return this._activeRequests$;
    }

    count() {
        return this.requests().pipe(
            map(requests => requests.length),
            distinctUntilChanged()
        );
    }

    any() {
        return this.count().pipe(
            map(requests => requests > 0),
            distinctUntilChanged()
        );
    }
}
