import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadingStrategy, RouteReuseStrategy } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { ActiveRequestsInterceptor, BodyInterceptor, ErrorInterceptor, UrlInterceptor } from '@app/app.interceptors';
import { CustomPreloadStrategy, CustomRouteReuseStrategy, routing } from '@app/app.routing';
import { MessagePageComponent } from '@app/message-page/message-page.component';
import { TokenComponent } from '@app/token/token.component';
import { GlobalErrorHandler } from '@app/_helpers/error-handler';
import { SharedModule } from '@app/_modules/shared.module';
import { ConfigService, TokenStoreService } from '@app/_services';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';

export function ConfigServiceFactory(config: ConfigService): Function
{
    return () => config.load();
}

export function jwtOptionsFactory(tokenStoreService: TokenStoreService)
{
    return {
        tokenGetter: () =>
        {
            return tokenStoreService.token;
        }
    };
}

export function TranslateLoaderFactory(http: HttpClient)
{
    return new TranslateHttpLoader(http, '/assets/translations/', '.json');
}

@NgModule({
    declarations: [AppComponent, TokenComponent, MessagePageComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler
            }
        }),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenStoreService]
            }
        }),
        routing
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigServiceFactory,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        {
            provide: PreloadingStrategy,
            useClass: CustomPreloadStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ActiveRequestsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BodyInterceptor,
            multi: true
        },
        TokenStoreService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
