import { Directive, ElementRef, Input } from '@angular/core';

const ATTRIBUTE_NAMES = ['ta', 'data-ta'];

@Directive({
    selector: '[ta]'
})
export class TestAutomationDirective {
    @Input()
    set ta(value: string | { [key: string]: any }) {
        // Set the attribute
        if (typeof value === 'string') {
            ATTRIBUTE_NAMES.forEach(ATTRIBUTE_NAME => this.el.nativeElement.setAttribute(ATTRIBUTE_NAME, value));
        } else {
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    ATTRIBUTE_NAMES.forEach(ATTRIBUTE_NAME =>
                        this.el.nativeElement.setAttribute(`${ATTRIBUTE_NAME}-${key}`, value[key])
                    );
                }
            }
        }
    }

    constructor(private el: ElementRef<Element>) {}
}
