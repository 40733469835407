var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { SupervisorService } from '@app/_services';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
var CustomMatPaginator = /** @class */ (function (_super) {
    __extends(CustomMatPaginator, _super);
    function CustomMatPaginator(translate, supervisorService) {
        var _this = _super.call(this) || this;
        _this.translate = translate;
        _this.supervisorService = supervisorService;
        // Did not implement nextPageLabel, previousPageLabel and changes on purpose
        // Labels are not used and the default changes
        _this.getRangeLabel = function (page, pageSize, length) {
            if (length === 0 || pageSize === 0) {
                return this.translate.instant('PAGINATION.PAGE_POSITION', {
                    startIndex: 0,
                    endIndex: 0,
                    length: length
                });
            }
            length = Math.max(length, 0);
            var startIndex = page * pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            var endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
            return this.translate.instant('PAGINATION.PAGE_POSITION', {
                startIndex: startIndex + 1,
                endIndex: endIndex,
                length: length
            });
        };
        _this._labelSubscription$ = combineLatest([
            _this.translate.get('PAGINATION.ITEMS_PER_PAGE').pipe(take(1)),
            _this.translate.get('PAGINATION.SELECTED_USER_COUNT').pipe(take(1)),
            _this.supervisorService.selectionCount()
        ]).pipe(map(function (_a) {
            var transItemsPerPage = _a[0], transSelectUserCount = _a[1], selectUserCount = _a[2];
            return {
                'transItemsPerPage': transItemsPerPage,
                'transSelectUserCount': transSelectUserCount,
                'selectUserCount': selectUserCount
            };
        })).subscribe(function (value) {
            _this.itemsPerPageLabel = value.transSelectUserCount + ': ' + value.selectUserCount + Array(10).fill('\xa0').join('') + value.transItemsPerPage + ':';
            // force paginator component to update
            _this.changes.next();
        });
        return _this;
    }
    CustomMatPaginator.prototype.ngOnDestroy = function () {
        this._labelSubscription$.unsubscribe();
    };
    return CustomMatPaginator;
}(MatPaginatorIntl));
export { CustomMatPaginator };
