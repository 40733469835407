<mat-card *ngIf="notReloading">

    <mat-card-content>
        <mat-icon>announcement</mat-icon>
    </mat-card-content>

    <ng-container>
        <mat-card-title>
            {{ translation?.title }}
        </mat-card-title>
        <mat-card-content>
            {{ translation?.message }}
        </mat-card-content>
    </ng-container>

</mat-card>