import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, skip } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ThemeService
{
    private theme = new BehaviorSubject<string>(null);

    constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient)
    {
        console.log('Theme service created');

        // Subscribe to set theme in document
        this.getTheme()
            .pipe(
                distinctUntilChanged(),
                skip(1), // Skip initial value of null
                map(theme => (theme ? `assets/themes/${theme}.css` : null))
            )
            .subscribe(styleSheetUrl => this.setDocumentStylesheet(styleSheetUrl));
    }

    private setDocumentStylesheet(url?: string)
    {
        console.log('Theme:' + url);

        const id = 'theme';
        const head = this.document.head;
        let link = <HTMLLinkElement>head.querySelector(`link#${id}`);

        if (url)
        {
            if (link == null)
            {
                link = this.document.createElement('link');
                link.id = id;
                link.rel = 'stylesheet';
                link.type = 'text/css';

                // Add element after last link stylesheet link
                head.appendChild(link);
            }

            // Set url
            link.href = url;
        } else if (link)
        {
            // Remove link element if no url and link elemen exists
            head.removeChild(link);
        }
    }

    getTheme()
    {
        return this.theme;
    }

    setTheme(theme: string)
    {
        return this.theme.next(theme);
    }
}
