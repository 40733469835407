import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from '@app/_services/logging.service';
import * as StackTrace from 'stacktrace-js';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler
{
    constructor(private injector: Injector)
    {
        super();
    }

    handleError(error: any): void
    {
        // If expected error cancel logging
        if (error.error != null && error.error.expected === true)
            return;

        const loggingService = this.injector.get(LoggingService);
        const message = error.message ? error.message : error.toString();
        const request = error.request;
        const localStorage = window.localStorage;
        const sessionStorage = window.sessionStorage;
        const userAgent = window.navigator.userAgent;

        // Get the stack frames and log it
        StackTrace.fromError(error).then(
            stackframes =>
            {
                loggingService.logError(message, { stackframes, request, localStorage, sessionStorage, userAgent });
            },
            () =>
            {
                // Log just message if stackframes failed
                loggingService.logError(message, { request, localStorage, sessionStorage, userAgent });
            }
        );

        // Handle error in angular
        super.handleError(error);
    }
}
