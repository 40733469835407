import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageType } from '@app/_helpers';
import { User } from '@app/_models';
import { TranslateService } from '@ngx-translate/core';
import { QueueingSubject } from 'queueing-subject';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { default as makeWebSocketObservable, GetWebSocketResponses, IWebSocket, WebSocketFactory } from 'rxjs-websockets';
import { delay, filter, map, retryWhen, share, switchMap } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ConfigService } from './config.service';
import { TokenStoreService } from './tokenstore.service';

const SESSIONPARAMETERPREFIX = 'session_';

@Injectable({
    providedIn: 'root'
})
export class TokenService
{
    private _sessionInput: QueueingSubject<any>;
    private _sessionMessages = new Subject<any>();
    private _sessionWebSocket: Observable<GetWebSocketResponses<string>>;
    private _socketSubscription: Subscription;
    private _loggingOff: boolean = false;

    public readonly token$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    get user(): User
    {
        return this.tokenStoreService.user;
    }

    get messages()
    {
        return this._sessionMessages;
    }

    static getSessionParameter<T>(key: string): T
    {
        const item = sessionStorage.getItem(`${SESSIONPARAMETERPREFIX}${key}`);
        if (item)
        {
            return <T>JSON.parse(item);
        }
    }

    static setSessionParameter<T>(key: string, data: T)
    {
        sessionStorage.setItem(`${SESSIONPARAMETERPREFIX}${key}`, JSON.stringify(data));
    }

    send(key: string, data: any)
    {
        this._sessionInput.next(JSON.stringify({ key, data }));
    }

    constructor(
        private tokenStoreService: TokenStoreService,
        private configService: ConfigService,
        private http: HttpClient,
        private router: Router,
        private alertService: AlertService,
        private location: Location,
        private translateService: TranslateService
    )
    {
        console.log('TokenService created');

        // Set the token from the store
        this.setToken(tokenStoreService.token, false);

        // Subscribe on alerts
        this._sessionMessages.pipe(filter(m => m.type === MessageType.Alert)).subscribe(m =>
        {
            this.alertService.success(m.tag, true);
        });

        // Subscribe to logoff events
        this._sessionMessages.pipe(filter(m => m.type === MessageType.Logoff)).subscribe(m =>
        {
            // show logoff message
            this.translateService.get('LOGGEDOFFBYSUPERVISOR').subscribe(translation =>
            {
                return this.alertService.success(translation, true);
            });

            this.logoutAndReload();
        });
    }

    authenticate(username: string, password: string, locationId: string = null)
    {
        return this.logon('authenticate', { username, password, locationId });
    }

    authenticateSupervisor(username: string, password: string, locationId: string = null)
    {
        return this.logon('supervisor/authenticate', { username, password, locationId });
    }

    authenticateProgress(password: string, learnperiodid: string)
    {
        return this.logon(`progress/${learnperiodid}/authenticate`, password);
    }

    authenticateToken(tokenId: string)
    {
        return this.logon('authenticate/token', tokenId);
    }

    authenticateUpgradeToken()
    {
        return this.logon('authenticate/upgradetoken', null);
    }

    authenticateRefreshToken()
    {
        return this.logon('authenticate/refreshtoken', null);
    }

    authenticateMoveToSTE(secureLocationId: string)
    {
        const originatingUrl = location.href;
        return this.http.post('authenticate/movetoste', { secureLocationId, originatingUrl });
    }

    activateTicket(ticketCode: string)
    {
        return this.logon('authenticate/activate', ticketCode);
    }

    authenticateSync(data: string)
    {
        return this.logon('authenticate/isync', data);
    }

    registerUser(username: string, learnperiodid: string, usergroupid: string)
    {
        return this.logon(`register/registeruser/${learnperiodid}/${usergroupid}`, username);
    }

    private logon(url: string, body: any)
    {
        // Map and share request
        const request = this.http.post<string>(url, body).pipe(share());

        // Subscribe to response to set token
        request.subscribe(
            token =>
            {
                this.setToken(token);
            },
            error => error
        );

        return request;
    }

    get authenticated()
    {
        return this.tokenStoreService.authenticated;
    }

    public logout()
    {
        if (this._loggingOff)
            return;

        this._loggingOff = true;
        // Delete authentication
        if (this.authenticated)
        {
            this.http.delete('authenticate').subscribe();
        }

        const originatingUrl: string = this.configService.config.ste ? this.tokenStoreService.getOriginatingUrl() : null;

        // Clear token
        this.setToken(null);

        // Reset session parameters
        Object.keys(sessionStorage)
            .filter(key => key.indexOf(SESSIONPARAMETERPREFIX) === 0)
            .forEach(key => sessionStorage.removeItem(key));

        if (originatingUrl)
        {
            location.href = originatingUrl;
        }

        this._loggingOff = false;
    }

    public setToken(token: string | null, saveToStorage = true)
    {
        // Set the token in the storage
        this.tokenStoreService.setToken(token, saveToStorage);

        // Handle session socket
        if (this.tokenStoreService.token != null && this.tokenStoreService.authenticated)
        {
            this.connect(token);
        } else if (this._sessionWebSocket)
        {
            this.disconnect();
        }

        this.token$.next(token);

        // Set config
        this.configService.update();
    }

    private connect(token: string)
    {
        // Disconnect if already exists
        if (this._sessionWebSocket)
        {
            this.disconnect();
        }

        // Make url
        const protocol: string = location.protocol === 'https:' ? 'wss' : 'ws';
        const sessionUrl = `${protocol}://${window.location.hostname}/api/session`;

        // Create new input stream
        this._sessionInput = new QueueingSubject<any>();

        // Create websocket
        const defaultWebsocketFactory: WebSocketFactory = (url: string, protocols: string | string[]): IWebSocket =>
            new WebSocket(sessionUrl, token);

        // abuse protocol parameter for sending token
        this._sessionWebSocket = makeWebSocketObservable(sessionUrl, {
            protocols: protocol,
            makeWebSocket: defaultWebsocketFactory
        });

        if (this._socketSubscription)
            this._socketSubscription.unsubscribe();

        // Publish session messages from connection session connection
        this._socketSubscription = this._sessionWebSocket.pipe(
            switchMap((getResponses: GetWebSocketResponses<string>) =>
            {
                return getResponses(this._sessionInput.pipe(map(request => JSON.stringify(request)))).pipe(
                    map(resp => JSON.parse(resp))
                );
            }),
            retryWhen(errors => errors.pipe(delay(1000))),
            filter(message => !!message)
        ).subscribe(message =>
        {
            this._sessionMessages.next(message);
        });

        (<any>window).taStopSocket = () => { this.disconnect(); };

        // Dummy Test Message
        this.send('dummy', `A-Team says hi!!!!`);
    }

    /** logs of the user and reloads the page (to let the authguard handle what should happen) */
    public logoutAndReload(): void
    {
        if (this._loggingOff)
            return;

        this._loggingOff = true;

        // Clear token
        this.setToken(null);

        // Get path to reload
        const path = this.location.path();

        // Reload current url
        this.router.navigateByUrl('/reload', { replaceUrl: true }).then(() =>
        {
            this._loggingOff = false;
            // let authguard handle what should happen
            this.router.navigateByUrl(path, { replaceUrl: true });
        });
    }

    private disconnect()
    {
        // Close socket (this will complete all subscribers)
        if (this._sessionWebSocket)
        {
            this._sessionWebSocket = null;

            if (this._socketSubscription) this._socketSubscription.unsubscribe();
        }

        // Reset observables
        this._sessionInput = null;
    }
}
