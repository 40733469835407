/// <reference path="../_providers/pagination.provider.ts" />
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatGridListModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatPaginatorIntl, MatPaginatorModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule } from '@angular/material';
import { MatBadgeModule } from '@angular/material/badge';
import { AboutDialogComponent, LoginComponent, PrivacyPolicyDialogComponent, ReadMoreComponent, ResultsComponent, SelectSecureLocationDialogComponent, StatusIconComponent, TipComponent } from '@app/_components';
import { ConnectionDialogComponent } from '@app/_components/connection-dialog/connection-dialog.component';
import { AnimateNumberDirective, DomChangeDirective, InfolandExpansionPanelDirective, PasswordValidatorDirective, SameSizeDirective, TestAutomationDirective } from '@app/_directives';
import { InfolandDate, SanitizeHtmlPipe } from '@app/_pipes';
import { CustomMatPaginator } from '@app/_providers/pagination.provider';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginator }, CookieService],
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatSelectModule,
        MatMenuModule,
        MatBadgeModule
    ],
    declarations: [
        SanitizeHtmlPipe,
        InfolandDate,
        InfolandExpansionPanelDirective,
        SameSizeDirective,
        PasswordValidatorDirective,
        DomChangeDirective,
        AnimateNumberDirective,
        TestAutomationDirective,
        ResultsComponent,
        LoginComponent,
        TipComponent,
        ReadMoreComponent,
        StatusIconComponent,
        PrivacyPolicyDialogComponent,
        ConnectionDialogComponent,
        AboutDialogComponent,
        SelectSecureLocationDialogComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatStepperModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatBadgeModule,
        TranslateModule,
        SanitizeHtmlPipe,
        InfolandDate,
        InfolandExpansionPanelDirective,
        SameSizeDirective,
        PasswordValidatorDirective,
        AnimateNumberDirective,
        TestAutomationDirective,
        DomChangeDirective,
        ReadMoreComponent,
        ResultsComponent,
        LoginComponent,
        TipComponent,
        StatusIconComponent,
        CdkTableModule
    ],
    entryComponents: [AboutDialogComponent, PrivacyPolicyDialogComponent, ConnectionDialogComponent, SelectSecureLocationDialogComponent]
})
export class SharedModule { }
