import { Injectable, NgZone } from '@angular/core';
import { bezier } from '@app/_helpers/index';

export interface AutoScrollOptions {
    duration: number;
    offsetTop: number;
    offsetBottom: number;
}

@Injectable({
    providedIn: 'root'
})
export class AutoScrollService {
    private easing: any;

    constructor(private zone: NgZone) {
        // set the cubic bezier animation
        this.easing = bezier(0.4, 0.0, 0.2, 1);
    }

    scrollToAnimation(element: HTMLElement | number, config: AutoScrollOptions = { duration: 400, offsetTop: 0, offsetBottom: 0 }) {
        if (element && element instanceof HTMLElement) {
            this.doScrollAnimation(this.determineScrollTo(element, config), config);
        } else {
            this.doScrollAnimation(element as number, config);
        }
    }

    scrollToAbsolute(element: HTMLElement | number, config: AutoScrollOptions = { duration: 0, offsetTop: 0, offsetBottom: 0 }) {
        if (element && element instanceof HTMLElement) {
            this.doScrollAbsolute(this.determineScrollTo(element, config), config);
        } else {
            this.doScrollAbsolute(element as number, config);
        }
    }

    // do scrolling without animation
    private doScrollAbsolute(targetScrollTop: number, options: AutoScrollOptions) {
        targetScrollTop += this.getStartScrollPosition();
        window.scrollTo(0, targetScrollTop);
    }

    // do scrolling with animation
    private doScrollAnimation(targetScrollTop: number, options: AutoScrollOptions) {
        // set start time for animation
        const timeStart = Date.now();
        const startScrollTop = this.getStartScrollPosition();
        targetScrollTop += startScrollTop;
        targetScrollTop -= options.offsetTop;

        this.zone.runOutsideAngular(() => {
            const step = (_targetScrollTop: number, _options: AutoScrollOptions) => {
                const elapsed = Date.now() - timeStart;

                // get the new position to scroll to
                const position = this.getPosition(startScrollTop, _targetScrollTop, elapsed, _options.duration);

                window.scrollTo(0, position);

                if (elapsed <= _options.duration) {
                    requestAnimationFrame(() => step(_targetScrollTop, _options));
                }
            };
            step(targetScrollTop, options);
        });
    }

    // determine the position to scroll to
    private determineScrollTo(element: HTMLElement, options: AutoScrollOptions) {
        const rect = element.getBoundingClientRect();
        const margin = parseInt(window.getComputedStyle(element)['marginTop'], 10) + options.offsetTop;

        // only start scrolling if the element is leaving the viewport
        let targetScrollTop: number =
            rect.bottom - (window.innerHeight || document.documentElement.clientHeight) + margin;

        if (targetScrollTop < 0) {
            targetScrollTop = 0;
        }

        // if the element is too long to scroll to, scroll to the top of the element.
        if (targetScrollTop + options.offsetBottom > rect.top) {
            targetScrollTop = rect.top - margin;
        }
        else {
            // scroll the element into view with the bottom offset included
            targetScrollTop += options.offsetBottom;
        }

        return targetScrollTop;
    }

    private getPosition(start: number, end: number, elapsed: number, duration: number): number {
        if (elapsed > duration) {
            return end;
        }
        return start + (end - start) * this.easing(elapsed / duration);
    }

    private getStartScrollPosition() {
        return window.scrollY || document.documentElement.scrollTop;
    }
}
